var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.proceed.apply(null, arguments)
          },
          keydown: function ($event) {
            return _vm.linkForm.errors.clear($event.target.name)
          },
        },
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-4 col-sm-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.trans("general.name"))),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.linkForm.name,
                      expression: "linkForm.name",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    value: "",
                    name: "name",
                    placeholder: _vm.trans("general.name"),
                  },
                  domProps: { value: _vm.linkForm.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.linkForm, "name", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: { "form-name": _vm.linkForm, "prop-name": "name" },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-4 col-sm-12" }, [
            _c("label", { attrs: { for: "" } }, [
              _vm._v(_vm._s(_vm.trans("link.link_url"))),
            ]),
            _vm._v(" "),
            _vm.linkForm.hashed_id !== ""
              ? _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    ref: "clone",
                    staticClass: "form-control",
                    attrs: { readonly: "" },
                    domProps: {
                      value: _vm.appLinkUrl + _vm.linkForm.hashed_id,
                    },
                    on: {
                      focus: function ($event) {
                        return $event.target.select()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group-append" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-secondary",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.copy.apply(null, arguments)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fa-solid fa-copy" })]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _vm.id
            ? _c("div", { staticClass: "col-12 col-md-4 col-sm-12" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.trans("general.header"))),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.linkForm.header,
                          expression: "linkForm.header",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        value: "",
                        name: "header",
                        placeholder: _vm.trans("general.header_here"),
                      },
                      domProps: { value: _vm.linkForm.header },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.linkForm, "header", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.linkForm,
                        "prop-name": "header",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.id
            ? _c("div", { staticClass: "col-12 col-md-4 col-sm-12" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.trans("general.sub_header"))),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.linkForm.sub_header,
                          expression: "linkForm.sub_header",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        value: "",
                        name: "sub_header",
                        placeholder: _vm.trans("general.sub_header_here"),
                      },
                      domProps: { value: _vm.linkForm.sub_header },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.linkForm,
                            "sub_header",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.linkForm,
                        "prop-name": "sub_header",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.id
            ? _c("div", { staticClass: "col-12 col-md-4 col-sm-12" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.trans("general.footer_text"))),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.linkForm.footer_text,
                          expression: "linkForm.footer_text",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        value: "",
                        name: "footer_text",
                        placeholder: _vm.trans("general.footer_text_here"),
                      },
                      domProps: { value: _vm.linkForm.footer_text },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.linkForm,
                            "footer_text",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.linkForm,
                        "prop-name": "footer_text",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _vm.linkForm.link_items.length > 0 && _vm.id
          ? _c("div", { staticClass: "row mt-3 justify-content-md-center" }, [
              _c(
                "div",
                { staticClass: "col-12 col-md-8 col-sm-12" },
                [
                  _c("show-tip", {
                    attrs: {
                      module: "link",
                      tip: "tip_link_url_at_least_one_active",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        attrs: { handle: ".sort-num" },
                        on: {
                          change: _vm.updateDraggable,
                          start: function ($event) {
                            _vm.isDragging = true
                          },
                          end: function ($event) {
                            _vm.isDragging = false
                          },
                        },
                        model: {
                          value: _vm.linkForm.link_items,
                          callback: function ($$v) {
                            _vm.$set(_vm.linkForm, "link_items", $$v)
                          },
                          expression: "linkForm.link_items",
                        },
                      },
                      "draggable",
                      _vm.dragOptions,
                      false
                    ),
                    _vm._l(_vm.linkForm.link_items, function (item, key) {
                      return _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-header d-flex align-items-center",
                          },
                          [
                            _c("span", {
                              staticClass:
                                "px-3 py-2 border mr-3 bg-white sort-num",
                              domProps: { textContent: _vm._s(key + 1) },
                            }),
                            _vm._v(" "),
                            item.link_item_types
                              ? _c(
                                  "span",
                                  { staticClass: "px-3 py-2 border mr-6" },
                                  [
                                    _c("img", {
                                      staticClass: "img-responsive mr-5",
                                      staticStyle: {
                                        "max-width": "35px",
                                        "max-height": "35px",
                                      },
                                      attrs: {
                                        src: _vm.getFirstMediaUrl(
                                          item.link_item_types
                                        ),
                                        alt: item.link_item_types.name,
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column mr-auto" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-inline" },
                                  [
                                    _c("label", { staticClass: "mr-3" }, [
                                      _vm._v(
                                        _vm._s(_vm.trans("general.url")) + ":"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.url,
                                          expression: "item.url",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        name: "url",
                                        placeholder:
                                          item.name +
                                          " " +
                                          _vm.trans("general.url"),
                                      },
                                      domProps: { value: item.url },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item,
                                            "url",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.linkForm,
                                        "prop-name":
                                          "link_items." + key + ".url",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column mr-auto" },
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: _vm.linkForm.link_items[key].active
                                      ? "success"
                                      : "default",
                                    disabled:
                                      _vm.linkForm.link_items[key].disabled,
                                  },
                                  model: {
                                    value: _vm.linkForm.link_items[key].active,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.linkForm.link_items[key],
                                        "active",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "linkForm.link_items[key].active",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn btn-info waves-effect waves-light pull-right mt-3",
            attrs: { type: "submit" },
          },
          [
            _vm.id
              ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
              : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))]),
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.id,
                expression: "id",
              },
            ],
            staticClass:
              "btn btn-danger waves-effect waves-light pull-right m-r-10 mt-3",
            attrs: { to: "/link" },
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.trans("general.cancel")) + "\n      "
            ),
          ]
        ),
        _vm._v(" "),
        !_vm.id
          ? _c(
              "button",
              {
                staticClass:
                  "btn btn-danger waves-effect waves-light pull-right m-r-10 mt-3",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("cancel")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.trans("general.cancel")) + "\n      ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
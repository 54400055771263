var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "wrapper" } }, [
    _c(
      "div",
      {
        staticClass: "login-register",
        staticStyle: { "background-image": "url(/images/background.jpg)" },
      },
      [
        _c(
          "div",
          { staticClass: "login-box card" },
          [
            _c("div", { staticClass: "card-body p-4" }, [
              _c("h3", { staticClass: "box-title m-b-20 text-center" }, [
                _vm._v(_vm._s(_vm.trans("auth.logging_in"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group m-b-0" }, [
                _c("div", { staticClass: "col-sm-12 text-center" }, [
                  _c(
                    "p",
                    [
                      _vm._v(_vm._s(_vm.trans("auth.back_to_login?")) + " "),
                      _c(
                        "router-link",
                        {
                          staticClass: "text-info m-l-5",
                          attrs: { to: "/login" },
                        },
                        [_c("b", [_vm._v(_vm._s(_vm.trans("auth.sign_in")))])]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("guest-footer"),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <form @submit.prevent="proceed" @keydown="linkItemTypeForm.errors.clear($event.target.name)">
      <div class="row">
        <div class="col-12 col-md-4 col-sm-12">
          <div class="form-group">
            <label for="">{{ trans('general.name') }}</label>
            <input class="form-control" type="text" value="" v-model="linkItemTypeForm.name" name="name"
                   :placeholder="trans('general.name')">
            <show-error :form-name="linkItemTypeForm" prop-name="name"></show-error>
          </div>
        </div>
        <div class="col-12 col-md-4 col-sm-12">
          <div class="form-group">
            <label for="">{{ trans('general.bg_color') }}</label>
            <br>
            <v-swatches v-model="linkItemTypeForm.bg_color"
                        :swatches="swatches"
                        row-length="5"
                        popover-x="left"
            ></v-swatches>
          </div>
        </div>

        <div class="col-12 col-md-4 col-sm-12">
          <div class="form-group">
            <h4 class="card-title">{{ trans('general.image') }}</h4>
            <image-upload
                ref="imageUpload"
                :id="id"
                :max-size="1000000"
                :min-size="10"
                :image-url="linkItemTypeForm.image_url || imageUrl"
                @fileSelected="onFileSelected"
                @fileRemoved="onFileRemoved"
            ></image-upload>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-info waves-effect waves-light pull-right mt-5">
        <span v-if="id">{{ trans('general.update') }}</span>
        <span v-else>{{ trans('general.save') }}</span>
      </button>

      <button v-if="!id" type="button" class="btn btn-danger waves-effect waves-light pull-right m-r-10 mt-5"
              @click="$emit('cancel')">{{ trans('general.cancel') }}
      </button>
    </form>
  </div>
</template>


<script>
import imageUpload from '../../components/image-upload'
import VSwatches from 'vue-swatches'


export default {
  components: {imageUpload, VSwatches},
  data() {
    return {
      linkItemTypeForm: new Form({
        name: '',
        bg_color: '',
        image: '',
        image_url: ''
      }),
      swatches: [
        ['#F64272', '#F6648B', '#F493A7', '#F891A6', '#FFCCD5'],
        ['#8b5aff', '#a27bff', '#b99cff', '#d0bdff', '#e8deff'],
        ['#51e5db', '#74ebe3', '#96f0ea', '#b9f5f1', '#dcfaf8'],
        ['#ffa51a', '#ffb748', '#ffc976', '#ffdba3', '#ffedd1']
      ],
    };
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    }
  },
  mounted() {
    if (this.id) {
      this.getLinkItemType();
    }
  },
  methods: {
    proceed() {
      const action = this.id ? this.updateLinkItemType : this.storeLinkItemType;
      action();
    },
    getLinkItemType() {
      axios.get('/api/link-item-type/' + this.id)
          .then(response => response.data)
          .then(response => {
            this.linkItemTypeForm.name = response.name;
            this.linkItemTypeForm.bg_color = response.styling.bg_color;
            this.linkItemTypeForm.image = response.media[0].original_url;
            this.linkItemTypeForm.image_url = response.media[0].original_url;
          })
          .catch(error => {
            helper.showDataErrorMsg(error);
            this.$router.push('/link');
          });
    },
    addFormData() {
      const formData = new FormData();
      formData.append('name', this.linkItemTypeForm.name);
      formData.append('bg_color', this.linkItemTypeForm.bg_color);
      if (this.linkItemTypeForm.image) {
        formData.append('image', this.linkItemTypeForm.image);
      }
      return formData;
    },
    storeLinkItemType() {
      const formData = this.addFormData();
      axios.post('/api/link-item-type', formData)
          .then(response => response.data)
          .then(response => {
            toastr.success(response.message);
            this.$emit('completed');
            this.resetForm();
            this.$refs.imageUpload.reset();
          })
          .catch(error => {
            helper.showErrorMsg(error);
          });
    },
    updateLinkItemType() {
      const formData = this.addFormData();
      formData.append('_method', 'PUT')
      axios.post('/api/link-item-type/' + this.id, formData)
          .then(response => {
            toastr.success(response.message);
            this.$router.push('/link-item-type');
          })
          .catch(error => {
            if (error.response) {
              helper.showErrorMsg(error.response.data);
            } else if (error.request) {
              helper.showErrorMsg('No response received from server');
            } else {
              helper.showErrorMsg(error.message);
            }
          });
    },
    onFileSelected(file) {
      this.linkItemTypeForm.image = file;
      this.linkItemTypeForm.image_url = URL.createObjectURL(file);
    },
    onFileRemoved() {
      this.linkItemTypeForm.image = null;
      this.linkItemTypeForm.image_url = null;
    },
    resetForm() {
      this.linkItemTypeForm.name = '';
      this.linkItemTypeForm.bg_color = '';
      this.linkItemTypeForm.image = '';
      this.linkItemTypeForm.image_url = '';
    },
  }
}
</script>

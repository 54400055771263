<template>
  <div>
    <div class="form-group text-center" v-if="imageUrl">

      <button v-if="imageUrl && imageUrl.length > 0" @click.prevent="removeImage"
              class="btn btn-sm btn-danger waves-effect waves-light m-t-10 m-b-10">{{ trans('general.remove') }}
      </button>
      <img :src="imageUrl" class="img-responsive" style="max-width:200px;">
    </div>
    <div class="form-group text-center m-t-20" v-else>
      <span id="fileselector" v-show="!previewUrl">
        <label class="btn btn-info">
          <input type="file" @change="previewImage" :id="id" class="upload-button">
          <i class="fas fa-upload margin-correction"></i> {{ trans('general.choose_image') }}
        </label>
      </span>
      <div class="form-group text-center" v-if="previewUrl">
        <img :src="previewUrl" class="img-responsive" style="max-width:200px;">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      default: ''
    },
    maxSize: {
      type: Number,
      default: 10000000 // 10MB by default
    },
    minSize: {
      type: Number,
      default: 0 // 0 bytes by default
    },
    imageUrl: {
      type: [String, File],
      default: null,
    },
  },
  data() {
    return {
      previewUrl: null,
      file: null,
    };
  },
  computed: {
    accept() {
      // Generate a string containing the accepted file types based on the allowed extensions
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
      return allowedExtensions.map(ext => `.${ext}`).join(',');
    }
  },
  methods: {
    reset() {
      this.previewUrl = null;
    },
    previewImage(event) {
      this.previewUrl = null;
      this.file = null;
      const file = event.target.files[0];
      if (file.size > this.maxSize) {
        alert(`File is too large (max ${this.maxSize / 1000000} MB). Please choose a smaller file.`);
        return;
      } else if (file.size < this.minSize) {
        alert(`File is too small (min ${this.minSize} bytes). Please choose a larger file.`);
        return;
      }
      this.file = file;
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.previewUrl = reader.result;
        this.$emit('fileSelected', file, this.previewUrl);
      };
    },
    removeImage() {
      this.previewUrl = null;
      this.file = null;
      this.$emit('fileRemoved');
    }
  },
};
</script>

<template>
  <div>
    <div class="page-titles p-3 border-bottom">
      <h3 class="text-themecolor">{{trans('link_item_type.link_item_type')}}
        <span class="card-subtitle"
              v-if="linkItemTypes">{{trans('general.total_result_found',{'count' : linkItemTypes.total})}}</span>
        <span class="card-subtitle" v-else>{{trans('general.no_result_found')}}</span>

        <sort-by class="pull-right" :order-by-options="orderByOptions" :sort-by="filterLinkForm.sort_by"
                 :order="filterLinkForm.order" @updateSortBy="value => {filterLinkForm.sort_by = value}"
                 @updateOrder="value => {filterLinkForm.order = value}"></sort-by>
        <button class="btn btn-info btn-sm pull-right m-r-10" v-if="!showFilterPanel"
                @click="showFilterPanel = !showFilterPanel"><i class="fas fa-filter"></i> <span
            class="d-none d-sm-inline">{{trans('general.filter')}}</span></button>
        <button class="btn btn-info btn-sm pull-right m-r-10" v-if="linkItemTypes.total && !showCreatePanel"
                @click="showCreatePanel = !showCreatePanel"><i class="fas fa-plus"></i> <span
            class="d-none d-sm-inline">{{trans('link_item_type.add_new_link_item_type')}}</span></button>
      </h3>
    </div>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <transition name="fade">
            <div class="card border-bottom" v-if="showCreatePanel">
              <div class="card-body p-4">
                <h4 class="card-title">{{trans('link_item_type.add_new_link_item_type')}}</h4>
                <link-item-type-form @completed="getLinkItemTypes"
                           @cancel="showCreatePanel = !showCreatePanel"></link-item-type-form>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="card border-bottom" v-if="showFilterPanel">
              <div class="card-body p-4">
                <h4 class="card-title">{{trans('general.filter')}}</h4>
                <div class="row">
                  <div class="col-6 col-md-3">
                    <div class="form-group">
                      <label for="">{{trans('general.name')}}</label>
                      <input class="form-control" name="name" v-model="filterLinkForm.name"
                             @keyup="getLinkItemTypes">
                    </div>
                  </div>
                </div>
                <button class="btn btn-danger btn-sm pull-right" v-if="showFilterPanel"
                        @click="showFilterPanel = !showFilterPanel">{{trans('general.cancel')}}
                </button>
              </div>
            </div>
          </transition>
          <div class="card">
            <div class="card-body">
              <div class="table-responsive" v-if="linkItemTypes.total">
                <table class="table">
                  <thead>
                  <tr>
                    <th>{{trans('general.image')}}</th>
                    <th>{{trans('general.name')}}</th>
                    <th>{{trans('general.created_at')}}</th>
                    <th class="table-option">{{trans('general.action')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(type, i) in linkItemTypes.data">
                    <td><img :src="type.media_url" class="img-responsive" style="max-width:25px; max-height: 25px"></td>
                    <td v-text="type.name"></td>
                    <td>{{type.created_at | moment}}</td>
                    <td class="table-option">
                      <div class="btn-group">
                        <button class="btn btn-info btn-sm" v-tooltip="trans('link_item_type.edit_link_item_type')"
                                @click.prevent="editLinkItemType(type)"><i class="fas fa-edit"></i></button>
                        <button class="btn btn-danger btn-sm" :key="type.id"
                                v-confirm="{ok: confirmDelete(type)}"
                                v-tooltip="trans('link_item_type.delete_link_item_type')"><i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <module-info v-if="!linkItemTypes.total" module="link" title="module_info_title"
                           description="module_info_description" icon="check-circle">
                <div slot="btn">
                  <button class="btn btn-info btn-md" v-if="!showCreatePanel"
                          @click="showCreatePanel = !showCreatePanel"><i class="fas fa-plus"></i>
                    {{trans('general.add_new')}}
                  </button>
                </div>
              </module-info>
              <pagination-record :page-length.sync="filterLinkForm.page_length" :records="linkItemTypes"
                                 @updateRecords="getLinkItemTypes"></pagination-record>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import linkItemTypeForm from './form';
import sortBy from '../../components/sort-by';

export default {
  components: {linkItemTypeForm, sortBy},
  data() {
    return {
      linkItemTypes: {
        total: 0,
        data: []
      },
      filterLinkForm: {
        name: '',
        sort_by: 'created_at',
        order: 'desc',
        page_length: helper.getConfig('page_length')
      },
      orderByOptions: [
        {
          value: 'name',
          translation: i18n.link.name
        }
      ],
      showCreatePanel: false,
      showFilterPanel: false
    };
  },
  mounted() {
    if (!helper.hasPermission('access-link-item-type')) {
      helper.notAccessibleMsg();
      this.$router.push('/home');
    }
    this.getLinkItemTypes();
  },
  methods: {
    hasPermission(permission) {
      return helper.hasPermission(permission);
    },
    getLinkItemTypes(page) {
      if (typeof page !== 'number') {
        page = 1;
      }
      let url = helper.getFilterURL(this.filterLinkForm);
      axios.get('/api/link-item-type?page=' + page + url)
          .then(response => response.data)
          .then(response => {
            this.linkItemTypes = response;
          })
          .catch(error => {
            helper.showDataErrorMsg(error);
          });
    },
    editLinkItemType(type) {
      this.$router.push('/link-item-type/' + type.id + '/edit');
    },
    confirmDelete(type) {
      return dialog => this.deleteLinkItemType(type);
    },
    deleteLinkItemType(type) {
      axios.delete('/api/link-item-type/' + type.id)
          .then(response => response.data)
          .then(response => {
            toastr.success(response.message);
            this.getLinkItemTypes();
          }).catch(error => {
        helper.showDataErrorMsg(error);
      });
    }
  },
  filters: {
    moment(date) {
      return helper.formatDate(date);
    }
  },
  watch: {
    filterLinkForm: {
      handler(val) {
        setTimeout(() => {
          this.getLinkItemTypes();
        }, 500)
      },
      deep: true
    }
  }
}
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(
          _vm._s(_vm.trans("locale.translation")) +
            " (" +
            _vm._s(_vm.locale.name) +
            ")\n            "
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function ($event) {
                return _vm.$router.push("/home")
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home"))),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right m-r-10",
            on: {
              click: function ($event) {
                return _vm.$router.push("/configuration/locale")
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-globe" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("locale.locale"))),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "dropdown pull-right m-r-10" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-info btn-sm",
              staticStyle: { "margin-top": "-5px" },
              attrs: {
                type: "button",
                href: "#",
                role: "button",
                id: "moduleLink",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false",
              },
            },
            [
              _c("i", { staticClass: "fas fa-boxes" }),
              _vm._v(" "),
              _c("span", { staticClass: "d-none d-sm-inline" }, [
                _vm._v(_vm._s(_vm.trans("locale.module"))),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                "dropdown-menu",
                _vm.getConfig("direction") == "ltr"
                  ? "dropdown-menu-right"
                  : "",
              ],
              attrs: { "aria-labelledby": "moduleLink" },
            },
            _vm._l(_vm.modules, function (mod) {
              return _c(
                "button",
                {
                  staticClass: "dropdown-item",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push(
                        "/configuration/locale/" + _vm.locale.locale + "/" + mod
                      )
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " + _vm._s(_vm.toWord(mod)) + " "
                  ),
                  mod == _vm.module
                    ? _c("span", { staticClass: "pull-right" }, [
                        _c("i", { staticClass: "fas fa-check" }),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body p-4" },
              [
                _c("show-tip", {
                  attrs: { module: "locale", tip: "tip_translation" },
                }),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.saveTranslation.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.getWordCount
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _vm._l(_vm.words, function (word, index) {
                                return [
                                  typeof word === "object"
                                    ? _vm._l(word, function (wrd, i) {
                                        return _c(
                                          "div",
                                          { staticClass: "col-12 col-sm-4" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticStyle: {
                                                      color: "red",
                                                    },
                                                    attrs: { for: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.trans(
                                                          _vm.module +
                                                            "." +
                                                            index +
                                                            "." +
                                                            i
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.words[index][i],
                                                      expression:
                                                        "words[index][i]",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    value: "",
                                                    name: index + "_" + i,
                                                  },
                                                  domProps: {
                                                    value: _vm.words[index][i],
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.words[index],
                                                        i,
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      })
                                    : [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-sm-4" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticStyle: {
                                                      color: "red",
                                                    },
                                                    attrs: { for: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.trans(
                                                          _vm.module +
                                                            "." +
                                                            index
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.words[index],
                                                      expression:
                                                        "words[index]",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    value: "",
                                                    name: index,
                                                  },
                                                  domProps: {
                                                    value: _vm.words[index],
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.words,
                                                        index,
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                ]
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm._m(0),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.getWordCount
                      ? _c("div", [
                          _c("p", { staticClass: "alert alert-danger" }, [
                            _vm._v("No record found!"),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("button", { staticClass: "btn btn-info btn-sm pull-right" }, [
        _vm._v("Save"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
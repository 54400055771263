<template>
  <div>
    <div class="page-titles p-3 border-bottom">
      <h3 class="text-themecolor">{{trans('permission.assign_permission')}}
        <button class="btn btn-info btn-sm pull-right" @click="$router.push('/home')"><i
            class="fas fa-home"></i> <span class="d-none d-sm-inline">{{trans('general.home')}}</span>
        </button>
        <button class="btn btn-info btn-sm pull-right m-r-10"
                @click="$router.push('/configuration/permission')"><i class="fas fa-key"></i> <span
            class="d-none d-sm-inline">{{trans('permission.permission')}}</span></button>
      </h3>
    </div>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive m-b-20">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>
                      <input type="text" name="search" class="form-control" v-model="search">
                    </th>
                    <th colspan="6">
                      <button type="button"
                              class="btn btn-sm btn-info waves-effect waves-light m-l-20 pull-right"
                              @click="savePermission">{{trans('general.save')}}
                      </button>
                      <router-link to="/configuration/permission"
                                   class="btn btn-sm btn-danger pull-right">
                        {{trans('general.back')}}
                      </router-link>
                    </th>
                  </tr>
                  <tr>
                    <th>{{trans('permission.permission')}}</th>
                    <th v-for="role in roles" class="text-center">
                      <label>
                        <input type="checkbox"
                               v-model="assignPermissionForm.data[role.id].checkAll"
                               @change="topCheckChanged(role.id)">
                        {{role.name}}
                      </label>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="permission in permissions">
                    <td>{{toWord(permission.name)}}</td>
                    <td v-for="role in roles" class="text-center">
                      <switches v-model="assignPermissionForm.data[role.id][permission.id]"
                                theme="bootstrap"
                                :color="assignPermissionForm.data[role.id][permission.id] ? 'success' : 'default' "
                                @input="permissionCheckChanged(role.id, permission.id)"></switches>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="12">
                      <button type="button"
                              class="btn btn-sm btn-info waves-effect waves-light m-l-20 pull-right"
                              @click="savePermission">{{trans('general.save')}}
                      </button>
                      <router-link to="/configuration/permission"
                                   class="btn btn-sm btn-danger pull-right">
                        {{trans('general.back')}}
                      </router-link>
                    </th>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col-12 col-lg-12 col-md-12">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import switches from 'vue-switches'

export default {
  components: {switches},
  data() {
    return {
      roles: [],
      permissions: [],
      permissionsHolder: [],
      assignPermissionForm: new Form({
        data: {}
      }),
      search: '',
    }
  },
  mounted() {
    if (!helper.hasPermission('access-configuration')) {
      helper.notAccessibleMsg();
      this.$router.push('/home');
    }
    axios.get('/api/permission/assign/pre-requisite')
        .then(response => response.data)
        .then(response => {
          this.permissions = response.permissions;
          this.permissionsHolder = response.permissions;
          this.roles = response.roles;
          this.assignPermissionForm.data = response.data;
          for (let i = 0; i < this.roles.length; i++) {
            let currentRolePermissions = Object.values(this.assignPermissionForm.data['' + this.roles[i].id]);
            let filtered = currentRolePermissions.filter((item) => {
              return !item;
            });
            if (filtered.length < 1) {
              this.assignPermissionForm.data['' + this.roles[i].id].checkAll = true;
            }
          }
        })
        .catch(error => {
          helper.showDataErrorMsg(error);
        });
  },
  methods: {
    savePermission() {
      axios.post('/api/permission/assign', {
        data: this.assignPermissionForm.data
      })
          .then(response => response.data)
          .then(response => {
            toastr.success(response.message);
          })
          .catch(error => {
            helper.showDataErrorMsg(error);
          });
    },
    toWord(str) {
      return helper.toWord(str);
    },
    topCheckChanged(roleId) {

      if (this.assignPermissionForm.data[roleId].checkAll) {
        for (let i = 0; i < this.permissions.length; i++) {
          this.assignPermissionForm.data[roleId][this.permissions[i].id] = true;
        }
      } else {
        for (let i = 0; i < this.permissions.length; i++) {
          this.assignPermissionForm.data[roleId][this.permissions[i].id] = false;
        }
      }
    },
    permissionCheckChanged(roleId, permissionId) {
      if (this.assignPermissionForm.data[roleId][permissionId]) {
        let currentRolePermissions = Object.values(this.assignPermissionForm.data[roleId]);

        // the checkAll property of this.assignPermissionForm.data[roleId] will always be false here
        // so we always have at least one item that's false in the Object.values() array
        // remove the last item from the values array which is the Object value for `checkAll`
        currentRolePermissions.splice(currentRolePermissions.length - 1, 1);

        let filtered = currentRolePermissions.filter((item) => {
          return !item;
        });

        if (filtered.length === 0) {
          this.assignPermissionForm.data[roleId].checkAll = true;
        }
      } else {
        this.assignPermissionForm.data[roleId].checkAll = false;
      }
    }
  },
  watch: {
    search(newVal, oldVal) {
      if (!newVal) {
        this.permissions = this.permissionsHolder;
        return;
      }
      this.permissions = this.permissionsHolder.filter(item => {
        return this.toWord(item.name).toLowerCase().indexOf(newVal.toLowerCase()) > -1;
      })
    }
  },
}
</script>

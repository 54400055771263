var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("ip_filter.ip_filter")) + "\n            "),
        _vm.ip_filters
          ? _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(
                _vm._s(
                  _vm.trans("general.total_result_found", {
                    count: _vm.ip_filters.total,
                  })
                )
              ),
            ])
          : _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(_vm._s(_vm.trans("general.no_result_found"))),
            ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function ($event) {
                return _vm.$router.push("/home")
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home"))),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right m-r-10",
            on: {
              click: function ($event) {
                _vm.showCreatePanel = !_vm.showCreatePanel
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-plus" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("ip_filter.add_new_ip_filter"))),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.showCreatePanel
                ? _c("div", { staticClass: "card border-bottom" }, [
                    _c("div", { staticClass: "card-body p-4" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(
                          _vm._s(_vm.trans("ip_filter.add_new_ip_filter"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("ip-filter-form", {
                              on: {
                                completed: _vm.getIpFilters,
                                cancel: function ($event) {
                                  _vm.showCreatePanel = !_vm.showCreatePanel
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _vm.ip_filters.total
                    ? _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("ip_filter.start_ip"))),
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("ip_filter.end_ip"))),
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  _vm._s(_vm.trans("ip_filter.description"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "table-option" }, [
                                _vm._v(_vm._s(_vm.trans("general.action"))),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.ip_filters.data, function (ip_filter) {
                              return _c("tr", [
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(ip_filter.start_ip),
                                  },
                                }),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(ip_filter.end_ip),
                                  },
                                }),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(ip_filter.description),
                                  },
                                }),
                                _vm._v(" "),
                                _c("td", { staticClass: "table-option" }, [
                                  _c("div", { staticClass: "btn-group" }, [
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.trans(
                                              "ip_filter.edit_ip_filter"
                                            ),
                                            expression:
                                              "trans('ip_filter.edit_ip_filter')",
                                          },
                                        ],
                                        staticClass: "btn btn-info btn-sm",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.editIpFilter(ip_filter)
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "fas fa-edit" })]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "confirm",
                                            rawName: "v-confirm",
                                            value: {
                                              ok: _vm.confirmDelete(ip_filter),
                                            },
                                            expression:
                                              "{ok: confirmDelete(ip_filter)}",
                                          },
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.trans(
                                              "ip_filter.delete_ip_filter"
                                            ),
                                            expression:
                                              "trans('ip_filter.delete_ip_filter')",
                                          },
                                        ],
                                        key: ip_filter.id,
                                        staticClass: "btn btn-danger btn-sm",
                                      },
                                      [_c("i", { staticClass: "fas fa-trash" })]
                                    ),
                                  ]),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.ip_filters.total
                    ? _c("module-info", {
                        attrs: {
                          module: "ip_filter",
                          title: "module_info_title",
                          description: "module_info_description",
                          icon: "ellipsis-v",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("pagination-record", {
                    attrs: {
                      "page-length": _vm.filterIpFilterForm.page_length,
                      records: _vm.ip_filters,
                    },
                    on: {
                      "update:pageLength": function ($event) {
                        return _vm.$set(
                          _vm.filterIpFilterForm,
                          "page_length",
                          $event
                        )
                      },
                      "update:page-length": function ($event) {
                        return _vm.$set(
                          _vm.filterIpFilterForm,
                          "page_length",
                          $event
                        )
                      },
                      updateRecords: _vm.getIpFilters,
                    },
                    nativeOn: {
                      change: function ($event) {
                        return _vm.getIpFilters.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
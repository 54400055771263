var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function ($event) {
          return _vm.localeForm.errors.clear($event.target.name)
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("locale.name"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localeForm.name,
                    expression: "localeForm.name",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  value: "",
                  name: "name",
                  placeholder: _vm.trans("locale.name"),
                },
                domProps: { value: _vm.localeForm.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.localeForm, "name", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.localeForm, "prop-name": "name" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("locale.locale"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localeForm.locale,
                    expression: "localeForm.locale",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  value: "",
                  name: "locale",
                  placeholder: _vm.trans("locale.locale"),
                },
                domProps: { value: _vm.localeForm.locale },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.localeForm, "locale", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.localeForm, "prop-name": "locale" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-info waves-effect waves-light pull-right",
          attrs: { type: "submit" },
        },
        [
          _vm.id
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))]),
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.id,
              expression: "id",
            },
          ],
          staticClass:
            "btn btn-danger waves-effect waves-light pull-right m-r-10",
          attrs: { to: "/configuration/locale" },
        },
        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
      ),
      _vm._v(" "),
      !_vm.id
        ? _c(
            "button",
            {
              staticClass:
                "btn btn-danger waves-effect waves-light pull-right m-r-10",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v(_vm._s(_vm.trans("general.cancel")))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("auth.change_password")) + " "),
        _c("span", { staticClass: "card-subtitle" }, [
          _vm._v(
            _vm._s(_vm.getAuthUser("full_name")) +
              " (" +
              _vm._s(_vm.getAuthUser("email")) +
              ")"
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function ($event) {
                return _vm.$router.push("/profile")
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-user" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("user.profile"))),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-sm-8" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body p-4" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.changePassword.apply(null, arguments)
                  },
                  keydown: function ($event) {
                    return _vm.passwordForm.errors.clear($event.target.name)
                  },
                },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("auth.current_password"))),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.passwordForm.current_password,
                              expression: "passwordForm.current_password",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "password",
                            value: "",
                            name: "current_password",
                            placeholder: _vm.trans("auth.current_password"),
                          },
                          domProps: {
                            value: _vm.passwordForm.current_password,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.passwordForm,
                                "current_password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.passwordForm,
                            "prop-name": "current_password",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("auth.new_password"))),
                        ]),
                        _vm._v(" "),
                        _vm.getConfig("password_strength_meter")
                          ? _c(
                              "div",
                              [
                                _c("password", {
                                  attrs: {
                                    name: "new_password",
                                    defaultClass: "form-control",
                                    placeholder: _vm.trans("auth.new_password"),
                                    required: false,
                                  },
                                  model: {
                                    value: _vm.passwordForm.new_password,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.passwordForm,
                                        "new_password",
                                        $$v
                                      )
                                    },
                                    expression: "passwordForm.new_password",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.passwordForm.new_password,
                                    expression: "passwordForm.new_password",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "password",
                                  name: "new_password",
                                  placeholder: _vm.trans("auth.new_password"),
                                },
                                domProps: {
                                  value: _vm.passwordForm.new_password,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.passwordForm,
                                      "new_password",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.passwordForm,
                            "prop-name": "new_password",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            _vm._s(_vm.trans("auth.new_password_confirmation"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.passwordForm.new_password_confirmation,
                              expression:
                                "passwordForm.new_password_confirmation",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "password",
                            value: "",
                            name: "new_password_confirmation",
                            placeholder: _vm.trans(
                              "auth.new_password_confirmation"
                            ),
                          },
                          domProps: {
                            value: _vm.passwordForm.new_password_confirmation,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.passwordForm,
                                "new_password_confirmation",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.passwordForm,
                            "prop-name": "new_password_confirmation",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-info waves-effect waves-light pull-right m-t-10",
                    attrs: { type: "submit" },
                  },
                  [_vm._v(_vm._s(_vm.trans("auth.change_password")))]
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("template.edit_template")) + "\n            "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function ($event) {
                return _vm.$router.push("/email-template")
              },
            },
          },
          [
            _c("i", { staticClass: "far fa-envelope" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("template.template"))),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body p-4" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                    keydown: function ($event) {
                      return _vm.templateForm.errors.clear($event.target.name)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("template.subject"))),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.templateForm.subject,
                            expression: "templateForm.subject",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          value: "",
                          name: "subject",
                          placeholder: _vm.trans("template.subject"),
                        },
                        domProps: { value: _vm.templateForm.subject },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.templateForm,
                              "subject",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.templateForm,
                          "prop-name": "subject",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("html-editor", {
                        attrs: {
                          name: "body",
                          model: _vm.templateForm.body,
                          isUpdate: "true",
                        },
                        on: {
                          "update:model": function ($event) {
                            return _vm.$set(_vm.templateForm, "body", $event)
                          },
                          clearErrors: function ($event) {
                            return _vm.templateForm.errors.clear("body")
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.templateForm,
                          "prop-name": "body",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "help-block" }, [
                    _vm._v(
                      _vm._s(_vm.trans("template.available_fields")) +
                        ": " +
                        _vm._s(_vm.fields)
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-info waves-effect waves-light pull-right m-t-10",
                      attrs: { type: "submit" },
                    },
                    [_vm._v(_vm._s(_vm.trans("general.save")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-danger waves-effect waves-light pull-right m-t-10 m-r-10",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/email-template")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
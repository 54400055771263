<template>
  <div>
    <div class="page-titles p-3 border-bottom">
      <h3 class="text-themecolor">{{ trans('link_item.edit_link_item') }}
        <button class="btn btn-info btn-sm pull-right" @click="$router.push('/link-item')"><i
            class="fas fa-check-circle"></i> <span class="d-none d-sm-inline">{{ trans('link_item.link_item') }}</span>
        </button>
      </h3>
    </div>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body p-4">
              <h4 class="card-title">{{ trans('link_item.edit_link_item') }}</h4>
              <link-item-form :id="id"></link-item-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import linkItemForm from './form';

export default {
  components: {linkItemForm},
  data() {
    return {
      id: this.$route.params.id
    }
  },
  mounted() {
    if (!helper.hasPermission('access-link-item')) {
      helper.notAccessibleMsg();
      this.$router.push('/home');
    }
  }
}
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "error-page", attrs: { id: "wrapper" } },
    [
      _c("div", { staticClass: "error-box" }, [
        _c(
          "div",
          { staticClass: "error-body text-center" },
          [
            _c("h1", [_vm._v("404")]),
            _vm._v(" "),
            _c("h3", { staticClass: "text-uppercase" }, [
              _vm._v(_vm._s(_vm.trans("general.page_not_found_heading"))),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-muted m-t-30 m-b-30" }, [
              _vm._v(_vm._s(_vm.trans("general.page_not_found_message"))),
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "btn btn-info btn-rounded waves-effect waves-light m-b-40",
                attrs: { to: "/" },
              },
              [_vm._v(_vm._s(_vm.trans("general.back_to_home")))]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("permission.permission")) + "\n            "),
        _vm.permissions
          ? _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(
                _vm._s(
                  _vm.trans("general.total_result_found", {
                    count: _vm.permissions.total,
                  })
                )
              ),
            ])
          : _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(_vm._s(_vm.trans("general.no_result_found"))),
            ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function ($event) {
                return _vm.$router.push("/home")
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home"))),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right m-r-10",
            on: {
              click: function ($event) {
                _vm.showCreatePanel = !_vm.showCreatePanel
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-plus" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("permission.add_new_permission"))),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right m-r-10",
            on: {
              click: function ($event) {
                return _vm.$router.push("/configuration/permission/assign")
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-user-plus" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("permission.assign_permission"))),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.showCreatePanel
                ? _c("div", { staticClass: "card border-bottom" }, [
                    _c("div", { staticClass: "card-body p-4" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(
                          _vm._s(_vm.trans("permission.add_new_permission"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 col-sm-4" },
                          [
                            _c("permission-form", {
                              on: {
                                completed: _vm.getPermissions,
                                cancel: function ($event) {
                                  _vm.showCreatePanel = !_vm.showCreatePanel
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _vm.permissions.total
                    ? _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("permission.name"))),
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "table-option" }, [
                                _vm._v(_vm._s(_vm.trans("general.action"))),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.permissions.data, function (permission) {
                              return _c("tr", [
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.toWord(permission.name)
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c("td", { staticClass: "table-option" }, [
                                  _c("div", { staticClass: "btn-group" }, [
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "confirm",
                                            rawName: "v-confirm",
                                            value: {
                                              ok: _vm.confirmDelete(permission),
                                            },
                                            expression:
                                              "{ok: confirmDelete(permission)}",
                                          },
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.trans(
                                              "permission.delete_permission"
                                            ),
                                            expression:
                                              "trans('permission.delete_permission')",
                                          },
                                        ],
                                        key: permission.id,
                                        staticClass: "btn btn-danger btn-sm",
                                      },
                                      [_c("i", { staticClass: "fas fa-trash" })]
                                    ),
                                  ]),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.permissions.total
                    ? _c("module-info", {
                        attrs: {
                          module: "permission",
                          title: "module_info_title",
                          description: "module_info_description",
                          icon: "key",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("pagination-record", {
                    attrs: {
                      "page-length": _vm.filterPermissionForm.page_length,
                      records: _vm.permissions,
                    },
                    on: {
                      "update:pageLength": function ($event) {
                        return _vm.$set(
                          _vm.filterPermissionForm,
                          "page_length",
                          $event
                        )
                      },
                      "update:page-length": function ($event) {
                        return _vm.$set(
                          _vm.filterPermissionForm,
                          "page_length",
                          $event
                        )
                      },
                      updateRecords: _vm.getPermissions,
                    },
                    nativeOn: {
                      change: function ($event) {
                        return _vm.getPermissions.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("general.home")) + "\n      "),
        _c(
          "button",
          {
            staticClass: "btn btn-danger btn-sm pull-right",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.logout.apply(null, arguments)
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-power-off" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("auth.logout"))),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.trans("user.user_preference"),
                expression: "trans('user.user_preference')",
              },
            ],
            staticClass:
              "btn btn-info btn-sm right-sidebar-toggle pull-right m-r-10",
          },
          [_c("i", { staticClass: "fas fa-cog" })]
        ),
        _vm._v(" "),
        !_vm.showFilterPanel
          ? _c(
              "button",
              {
                staticClass: "btn btn-info btn-sm pull-right m-r-10",
                on: {
                  click: function ($event) {
                    _vm.showFilterPanel = !_vm.showFilterPanel
                  },
                },
              },
              [
                _c("i", { staticClass: "fas fa-filter" }),
                _vm._v(" "),
                _c("span", { staticClass: "d-none d-sm-inline" }, [
                  _vm._v(_vm._s(_vm.trans("general.filter"))),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _vm.hasRole("admin")
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-sm-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body px-3 pt-3" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.trans("dashboard.period_registered_user", {
                            period: _vm.trans("dashboard.total"),
                          })
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c("h2", { staticClass: "font-light m-b-0" }, [
                      _c("i", {
                        staticClass: "fas fa-users fa-lg pull-right m-r-40",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "pull-left" }, [
                        _vm._v(_vm._s(_vm.users)),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body px-3 pt-3" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.trans("dashboard.period_registered_user", {
                            period: _vm.trans("dashboard.today"),
                          })
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c("h2", { staticClass: "font-light m-b-0" }, [
                      _c("i", {
                        staticClass: "fas fa-users fa-lg pull-right m-r-40",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "pull-left" }, [
                        _vm._v(_vm._s(_vm.today_registered_users)),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body px-3 pt-3" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.trans("dashboard.period_registered_user", {
                            period: _vm.trans("dashboard.week"),
                          })
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c("h2", { staticClass: "font-light m-b-0" }, [
                      _c("i", {
                        staticClass: "fas fa-users fa-lg pull-right m-r-40",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "pull-left" }, [
                        _vm._v(_vm._s(_vm.weekly_registered_users)),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-3" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body px-3 pt-3" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.trans("dashboard.period_registered_user", {
                            period: _vm.trans("dashboard.month"),
                          })
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c("h2", { staticClass: "font-light m-b-0" }, [
                      _c("i", {
                        staticClass: "fas fa-users fa-lg pull-right m-r-40",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "pull-left" }, [
                        _vm._v(_vm._s(_vm.monthly_registered_users)),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasRole("admin")
        ? _c("div", { staticClass: "row mt-0 border-top" }, [
            _c("div", { staticClass: "col-12 col-sm-6" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "px-3 pt-3" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(
                        _vm._s(_vm.trans("activity.activity_log")) +
                          "\n                "
                      ),
                      !_vm.activity_logs.length
                        ? _c("span", { staticClass: "card-subtitle" }, [
                            _vm._v(
                              _vm._s(_vm.trans("general.no_result_found"))
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.activity_logs.length
                    ? _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table table-hover" }, [
                          _c("thead", [
                            _c("tr", [
                              _vm.hasRole("admin")
                                ? _c("th", [
                                    _vm._v(_vm._s(_vm.trans("user.user"))),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("activity.activity"))),
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "table-option" }, [
                                _vm._v(_vm._s(_vm.trans("activity.date_time"))),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.activity_logs, function (activity_log) {
                              return _c("tr", [
                                _vm.hasRole("admin")
                                  ? _c("td", {
                                      domProps: {
                                        textContent: _vm._s(
                                          activity_log.user.profile.first_name +
                                            " " +
                                            activity_log.user.profile.last_name
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.trans(
                                          "activity." + activity_log.activity,
                                          {
                                            activity: _vm.trans(
                                              activity_log.module +
                                                "." +
                                                activity_log.module
                                            ),
                                          }
                                        )
                                      ) +
                                      "\n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "table-option" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("momentDateTime")(
                                        activity_log.created_at
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-6" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "px-3 pt-3" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(
                        _vm._s(_vm.trans("todo.todo")) + "\n                "
                      ),
                      !_vm.todos.length
                        ? _c("span", { staticClass: "card-subtitle" }, [
                            _vm._v(
                              _vm._s(_vm.trans("general.no_result_found"))
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.todos.length
                    ? _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("todo.title"))),
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("todo.status"))),
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "table-option" }, [
                                _vm._v(_vm._s(_vm.trans("todo.date"))),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.todos, function (todo) {
                              return _c("tr", [
                                _c("td", {
                                  domProps: { textContent: _vm._s(todo.title) },
                                }),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.getStatus(todo)),
                                  },
                                }),
                                _vm._v(" "),
                                _c("td", { staticClass: "table-option" }, [
                                  _vm._v(_vm._s(_vm._f("moment")(todo.date))),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "right-sidebar page-wrapper-header" }, [
      _c("div", { staticClass: "slimscrollright" }, [
        _c("div", { staticClass: "rpanel-title" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.trans("user.user_preference")) +
              "\n        "
          ),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "r-panel-body" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.updatePreference.apply(null, arguments)
                },
                keydown: function ($event) {
                  return _vm.preferenceForm.errors.clear($event.target.name)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("configuration.color_theme"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.preferenceForm.color_theme,
                              expression: "preferenceForm.color_theme",
                            },
                          ],
                          staticClass: "custom-select col-12",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.preferenceForm,
                                "color_theme",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.color_themes, function (option) {
                          return _c(
                            "option",
                            { domProps: { value: option.value } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(option.text) +
                                  "\n                  "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.preferenceForm,
                          "prop-name": "color_theme",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("configuration.direction"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.preferenceForm.direction,
                              expression: "preferenceForm.direction",
                            },
                          ],
                          staticClass: "custom-select col-12",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.preferenceForm,
                                "direction",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.directions, function (option) {
                          return _c(
                            "option",
                            { domProps: { value: option.value } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(option.text) +
                                  "\n                  "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.preferenceForm,
                          "prop-name": "direction",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("configuration.sidebar"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.preferenceForm.sidebar,
                              expression: "preferenceForm.sidebar",
                            },
                          ],
                          staticClass: "custom-select col-12",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.preferenceForm,
                                "sidebar",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.sidebar, function (option) {
                          return _c(
                            "option",
                            { domProps: { value: option.value } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(option.text) +
                                  "\n                  "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.preferenceForm,
                          "prop-name": "sidebar",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 col-sm-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("locale.locale"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.preferenceForm.locale,
                              expression: "preferenceForm.locale",
                            },
                          ],
                          staticClass: "custom-select col-12",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.preferenceForm,
                                "locale",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.locales, function (option) {
                          return _c(
                            "option",
                            { domProps: { value: option.value } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(option.text) +
                                  "\n                  "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.preferenceForm,
                          "prop-name": "sidebar",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-info waves-effect waves-light pull-right m-t-10",
                  attrs: { type: "submit" },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.trans("general.save")) +
                      "\n          "
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "container-fluid pt-0" },
      [
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.showFilterPanel
            ? _c("div", { staticClass: "card border-bottom" }, [
                _c("div", { staticClass: "card-body p-4" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(_vm._s(_vm.trans("general.filter"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("date-range-picker", {
                            attrs: {
                              "start-date": _vm.filterLinkForm.start_date,
                              "end-date": _vm.filterLinkForm.end_date,
                              label: _vm.trans("general.date_between"),
                            },
                            on: {
                              "update:startDate": function ($event) {
                                return _vm.$set(
                                  _vm.filterLinkForm,
                                  "start_date",
                                  $event
                                )
                              },
                              "update:start-date": function ($event) {
                                return _vm.$set(
                                  _vm.filterLinkForm,
                                  "start_date",
                                  $event
                                )
                              },
                              "update:endDate": function ($event) {
                                return _vm.$set(
                                  _vm.filterLinkForm,
                                  "end_date",
                                  $event
                                )
                              },
                              "update:end-date": function ($event) {
                                return _vm.$set(
                                  _vm.filterLinkForm,
                                  "end_date",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.showFilterPanel
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-danger btn-sm pull-right",
                          on: {
                            click: function ($event) {
                              _vm.showFilterPanel = !_vm.showFilterPanel
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.trans("general.cancel")) + "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row justify-content-center mt-3" }, [
          !_vm.hasRole("admin")
            ? _c("div", { staticClass: "col-12 col-md-12 col-sm-12" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.proceed.apply(null, arguments)
                      },
                      keydown: function ($event) {
                        return _vm.linkForm.errors.clear($event.target.name)
                      },
                    },
                  },
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.trans("user.send_email_report"))),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.linkForm.send_email_report,
                          expression: "linkForm.send_email_report",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.linkForm.send_email_report)
                          ? _vm._i(_vm.linkForm.send_email_report, null) > -1
                          : _vm.linkForm.send_email_report,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.linkForm.send_email_report,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.linkForm,
                                    "send_email_report",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.linkForm,
                                    "send_email_report",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.linkForm, "send_email_report", $$c)
                            }
                          },
                          function ($event) {
                            $event.preventDefault()
                            return _vm.updateEmailSendPreference.apply(
                              null,
                              arguments
                            )
                          },
                        ],
                      },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-12 col-sm-12" }, [
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.links.data, function (link) {
                return _c("div", { staticClass: "col-12 col-md-6" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-md-4" }, [
                          _c("h5", { staticClass: "card-text text-muted" }, [
                            _vm._v(_vm._s(link.name)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-md-4" }, [
                          _c("h5", { staticClass: "card-text text-muted" }, [
                            _vm._v(
                              _vm._s(_vm.trans("general.url")) +
                                ": " +
                                _vm._s(_vm.appUrl + link.hashed_id)
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-md-4" }, [
                          _c(
                            "span",
                            { staticClass: "float-right" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "card-link",
                                  attrs: { to: "/link/" + link.id + "/edit" },
                                },
                                [_vm._v(_vm._s(_vm.trans("general.edit")))]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mt-4" }, [
                        _c("div", { staticClass: "col-12 col-md-6" }, [
                          _c("h3", [
                            _vm._v(
                              _vm._s(_vm.trans("link.page_viewed")) + ": "
                            ),
                            _c("strong", [_vm._v(_vm._s(link.page_viewed))]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mt-4" }, [
                        _c("div", { staticClass: "col-12 col-md-6" }, [
                          _c("h3", [
                            _vm._v(
                              _vm._s(_vm.trans("link_item.btn_clicked")) + ": "
                            ),
                            _c("strong", [
                              _vm._v(_vm._s(link.link_items_sum_btn_clicked)),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass:
          "btn btn-danger btn-sm right-sidebar-toggle pull-right m-r-10",
      },
      [_c("i", { staticClass: "fas fa-times" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.imageUrl
      ? _c("div", { staticClass: "form-group text-center" }, [
          _vm.imageUrl && _vm.imageUrl.length > 0
            ? _c(
                "button",
                {
                  staticClass:
                    "btn btn-sm btn-danger waves-effect waves-light m-t-10 m-b-10",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.removeImage.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.trans("general.remove")) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("img", {
            staticClass: "img-responsive",
            staticStyle: { "max-width": "200px" },
            attrs: { src: _vm.imageUrl },
          }),
        ])
      : _c("div", { staticClass: "form-group text-center m-t-20" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.previewUrl,
                  expression: "!previewUrl",
                },
              ],
              attrs: { id: "fileselector" },
            },
            [
              _c("label", { staticClass: "btn btn-info" }, [
                _c("input", {
                  staticClass: "upload-button",
                  attrs: { type: "file", id: _vm.id },
                  on: { change: _vm.previewImage },
                }),
                _vm._v(" "),
                _c("i", { staticClass: "fas fa-upload margin-correction" }),
                _vm._v(
                  " " + _vm._s(_vm.trans("general.choose_image")) + "\n      "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.previewUrl
            ? _c("div", { staticClass: "form-group text-center" }, [
                _c("img", {
                  staticClass: "img-responsive",
                  staticStyle: { "max-width": "200px" },
                  attrs: { src: _vm.previewUrl },
                }),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
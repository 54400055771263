var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("template.template")) + " \n            "),
        _vm.email_templates
          ? _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(
                _vm._s(
                  _vm.trans("general.total_result_found", {
                    count: _vm.email_templates.total,
                  })
                )
              ),
            ])
          : _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(_vm._s(_vm.trans("general.no_result_found"))),
            ]),
        _vm._v(" "),
        _vm.email_templates.total && !_vm.showCreatePanel
          ? _c(
              "button",
              {
                staticClass: "btn btn-info btn-sm pull-right m-r-10",
                on: {
                  click: function ($event) {
                    _vm.showCreatePanel = !_vm.showCreatePanel
                  },
                },
              },
              [
                _c("i", { staticClass: "fas fa-plus" }),
                _vm._v(" "),
                _c("span", { staticClass: "d-none d-sm-inline" }, [
                  _vm._v(_vm._s(_vm.trans("template.add_new_template"))),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.showCreatePanel
                ? _c("div", { staticClass: "card border-bottom" }, [
                    _c("div", { staticClass: "card-body p-4" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.trans("template.add_new_template"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submit.apply(null, arguments)
                            },
                            keydown: function ($event) {
                              return _vm.templateForm.errors.clear(
                                $event.target.name
                              )
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12 col-sm-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(_vm._s(_vm.trans("template.name"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.templateForm.name,
                                        expression: "templateForm.name",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      value: "",
                                      name: "name",
                                      placeholder: _vm.trans("template.name"),
                                    },
                                    domProps: { value: _vm.templateForm.name },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.templateForm,
                                          "name",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.templateForm,
                                      "prop-name": "name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12 col-sm-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("template.category"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-select", {
                                    attrs: {
                                      label: "name",
                                      name: "category",
                                      id: "category",
                                      options: _vm.categories,
                                      placeholder: _vm.trans(
                                        "template.select_category"
                                      ),
                                    },
                                    on: {
                                      select: function ($event) {
                                        return _vm.templateForm.errors.clear(
                                          "category"
                                        )
                                      },
                                      close: function ($event) {
                                        _vm.templateForm.category =
                                          _vm.selected_category.id
                                      },
                                      remove: function ($event) {
                                        _vm.templateForm.category = ""
                                      },
                                    },
                                    model: {
                                      value: _vm.selected_category,
                                      callback: function ($$v) {
                                        _vm.selected_category = $$v
                                      },
                                      expression: "selected_category",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.templateForm,
                                      "prop-name": "category",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-info btn-sm waves-effect waves-light pull-right",
                              attrs: { type: "submit" },
                            },
                            [_vm._v(_vm._s(_vm.trans("general.save")))]
                          ),
                          _vm._v(" "),
                          _vm.showCreatePanel
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-danger btn-sm pull-right m-r-10",
                                  on: {
                                    click: function ($event) {
                                      _vm.showCreatePanel = !_vm.showCreatePanel
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _vm.email_templates.total
                    ? _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("template.name"))),
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("template.category"))),
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(_vm._s(_vm.trans("template.subject"))),
                              ]),
                              _vm._v(" "),
                              _c("th", { staticClass: "table-option" }, [
                                _vm._v(_vm._s(_vm.trans("general.action"))),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.email_templates.data,
                              function (email_template) {
                                return _c("tr", [
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(email_template.name),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.toWord(email_template.category)
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(
                                        email_template.subject
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "table-option" }, [
                                    _c("div", { staticClass: "btn-group" }, [
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.trans(
                                                "template.edit_template"
                                              ),
                                              expression:
                                                "trans('template.edit_template')",
                                            },
                                          ],
                                          staticClass: "btn btn-info btn-sm",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.editEmailTemplate(
                                                email_template
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-edit",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      !email_template.is_default
                                        ? _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "confirm",
                                                  rawName: "v-confirm",
                                                  value: {
                                                    ok: _vm.confirmDelete(
                                                      email_template
                                                    ),
                                                  },
                                                  expression:
                                                    "{ok: confirmDelete(email_template)}",
                                                },
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: _vm.trans(
                                                    "template.delete_template"
                                                  ),
                                                  expression:
                                                    "trans('template.delete_template')",
                                                },
                                              ],
                                              key: email_template.id,
                                              staticClass:
                                                "btn btn-danger btn-sm",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-trash",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.email_templates.total
                    ? _c(
                        "module-info",
                        {
                          attrs: {
                            module: "template",
                            title: "module_info_title",
                            description: "module_info_description",
                            icon: "envelope",
                          },
                        },
                        [
                          _c("div", { attrs: { slot: "btn" }, slot: "btn" }, [
                            !_vm.showCreatePanel
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-info btn-md",
                                    on: {
                                      click: function ($event) {
                                        _vm.showCreatePanel =
                                          !_vm.showCreatePanel
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fas fa-plus" }),
                                    _vm._v(
                                      " " + _vm._s(_vm.trans("general.add_new"))
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("pagination-record", {
                    attrs: {
                      "page-length": _vm.filterEmailTemplateForm.page_length,
                      records: _vm.email_templates,
                    },
                    on: {
                      "update:pageLength": function ($event) {
                        return _vm.$set(
                          _vm.filterEmailTemplateForm,
                          "page_length",
                          $event
                        )
                      },
                      "update:page-length": function ($event) {
                        return _vm.$set(
                          _vm.filterEmailTemplateForm,
                          "page_length",
                          $event
                        )
                      },
                      updateRecords: _vm.getEmailTemplates,
                    },
                    nativeOn: {
                      change: function ($event) {
                        return _vm.getEmailTemplates.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div class="page-titles p-3 border-bottom">
      <h3 class="text-themecolor">{{trans('link.link')}}
        <span class="card-subtitle"
              v-if="links">{{trans('general.total_result_found',{'count' : links.total})}}</span>
        <span class="card-subtitle" v-else>{{trans('general.no_result_found')}}</span>

        <sort-by class="pull-right" :order-by-options="orderByOptions" :sort-by="filterLinkForm.sort_by"
                 :order="filterLinkForm.order" @updateSortBy="value => {filterLinkForm.sort_by = value}"
                 @updateOrder="value => {filterLinkForm.order = value}"></sort-by>
        <button class="btn btn-info btn-sm pull-right m-r-10" v-if="!showFilterPanel"
                @click="showFilterPanel = !showFilterPanel"><i class="fas fa-filter"></i> <span
            class="d-none d-sm-inline">{{trans('general.filter')}}</span></button>
        <button class="btn btn-info btn-sm pull-right m-r-10" v-if="links.total && !showCreatePanel"
                @click="showCreatePanel = !showCreatePanel"><i class="fas fa-plus"></i> <span
            class="d-none d-sm-inline">{{trans('link.add_new_link')}}</span></button>
      </h3>
    </div>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <transition name="fade">
            <div class="card border-bottom" v-if="showCreatePanel">
              <div class="card-body p-4">
                <h4 class="card-title">{{trans('link.add_new_link')}}</h4>
                <link-form @completed="getLinks"
                           @cancel="showCreatePanel = !showCreatePanel"></link-form>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="card border-bottom" v-if="showFilterPanel">
              <div class="card-body p-4">
                <h4 class="card-title">{{trans('general.filter')}}</h4>
                <div class="row">
                  <div class="col-6 col-md-3">
                    <div class="form-group">
                      <label for="">{{trans('general.name')}}</label>
                      <input class="form-control" name="name" v-model="filterLinkForm.name"
                             @keyup="getLinks">
                    </div>
                  </div>
                </div>
                <button class="btn btn-danger btn-sm pull-right" v-if="showFilterPanel"
                        @click="showFilterPanel = !showFilterPanel">{{trans('general.cancel')}}
                </button>
              </div>
            </div>
          </transition>
          <show-tip v-if="links.total" module="link" tip="tip_link_url_open_if_not_logged_in"></show-tip>
          <div class="card">
            <div class="card-body">
              <div class="table-responsive" v-if="links.total">
                <table class="table">
                  <thead>
                  <tr>
                    <th>{{trans('general.name')}}</th>
                    <th>{{trans('general.url')}}</th>
                    <th>{{trans('general.created_at')}}</th>
                    <th class="table-option">{{trans('general.action')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(link, i) in links.data">
                    <td v-text="link.name"></td>
                    <td>
                      {{appLinkUrl}}{{link.hashed_id}}
                    </td>
                    <td>{{link.created_at | moment}}</td>
                    <td class="table-option">
                      <div class="btn-group">
                        <button class="btn btn-info btn-sm" v-tooltip="trans('link.edit_link')"
                                @click.prevent="editLink(link)"><i class="fas fa-edit"></i></button>
                        <button class="btn btn-danger btn-sm" :key="link.id"
                                v-confirm="{ok: confirmDelete(link)}"
                                v-tooltip="trans('link.delete_link')"><i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <module-info v-if="!links.total" module="link" title="module_info_title"
                           description="module_info_description" icon="check-circle">
                <div slot="btn">
                  <button class="btn btn-info btn-md" v-if="!showCreatePanel"
                          @click="showCreatePanel = !showCreatePanel"><i class="fas fa-plus"></i>
                    {{trans('general.add_new')}}
                  </button>
                </div>
              </module-info>
              <pagination-record :page-length.sync="filterLinkForm.page_length" :records="links"
                                 @updateRecords="getLinks"></pagination-record>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import linkForm from './form';
import sortBy from '../../components/sort-by';

export default {
  components: {linkForm, sortBy},
  data() {
    return {
      links: {
        total: 0,
        data: []
      },
      filterLinkForm: {
        name: '',
        sort_by: 'created_at',
        order: 'desc',
        page_length: helper.getConfig('page_length')
      },
      orderByOptions: [
        {
          value: 'name',
          translation: i18n.link.name
        }
      ],
      showCreatePanel: false,
      showFilterPanel: false,
      appLinkUrl: process.env.MIX_PUBLIC_LINK_URL
    };
  },

  mounted() {
    if (!helper.hasPermission('access-link')) {
      helper.notAccessibleMsg();
      this.$router.push('/home');
    }
    this.getLinks();
  },
  methods: {
    hasPermission(permission) {
      return helper.hasPermission(permission);
    },
    getLinks(page) {
      if (typeof page !== 'number') {
        page = 1;
      }
      let url = helper.getFilterURL(this.filterLinkForm);
      axios.get('/api/link?page=' + page + url)
          .then(response => response.data)
          .then(response => {
            this.links = response;
          })
          .catch(error => {
            helper.showDataErrorMsg(error);
          });
    },
    editLink(link) {
      this.$router.push('/link/' + link.id + '/edit');
    },
    confirmDelete(link) {
      return dialog => this.deleteLink(link);
    },
    deleteLink(link) {
      axios.delete('/api/link/' + link.id)
          .then(response => response.data)
          .then(response => {
            toastr.success(response.message);
            this.getLinks();
          }).catch(error => {
        helper.showDataErrorMsg(error);
      });
    },
    goToPublicLink(item) {
      let route = this.$router.push(this.appLinkUrl + item.hashed_id);
      window.open(route.href, '_blank');
    }
  },
  filters: {
    moment(date) {
      return helper.formatDate(date);
    }
  },
  watch: {
    filterLinkForm: {
      handler(val) {
        setTimeout(() => {
          this.getLinks();
        }, 500)
      },
      deep: true
    }
  }
}
</script>

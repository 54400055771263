var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.defaultImage
      ? _c("div", { staticClass: "form-group text-center" }, [
          _c("img", {
            staticClass: "img-responsive",
            staticStyle: { "max-width": "200px" },
            attrs: { src: _vm.getImage },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "form-group text-center m-t-20" }, [
      _c("div", [
        _vm.defaultImage
          ? _c(
              "button",
              {
                directives: [
                  {
                    name: "confirm",
                    rawName: "v-confirm",
                    value: { ok: _vm.confirmRemove(_vm.imageSource) },
                    expression: "{ok: confirmRemove(imageSource)}",
                  },
                ],
                staticClass:
                  "btn btn-sm btn-danger waves-effect waves-light m-t-10 m-b-10",
                attrs: { type: "button" },
              },
              [_vm._v(_vm._s(_vm.trans("general.remove")))]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.source,
              expression: "!source",
            },
          ],
          attrs: { id: "fileselector" },
        },
        [
          _c("label", { staticClass: "btn btn-info" }, [
            _c("input", {
              staticClass: "upload-button",
              attrs: { type: "file", id: _vm.id },
              on: { change: _vm.previewImage },
            }),
            _vm._v(" "),
            _c("i", { staticClass: "fas fa-upload margin-correction" }),
            _vm._v(
              " " + _vm._s(_vm.trans("general.choose_image")) + "\n            "
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.source
      ? _c("div", { staticClass: "form-group text-center" }, [
          _c("img", {
            staticClass: "img-responsive",
            staticStyle: { "max-width": "200px" },
            attrs: { src: _vm.source },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "text-center" }, [
      _vm.source
        ? _c(
            "button",
            {
              staticClass: "btn btn-info waves-effect waves-light m-t-10",
              attrs: { type: "submit" },
              on: { click: _vm.uploadImage },
            },
            [_vm._v(_vm._s(_vm.trans("general.upload")))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.source
        ? _c(
            "button",
            {
              staticClass: "btn btn-danger waves-effect waves-light m-t-10",
              attrs: { type: "button" },
              on: { click: _vm.cancelUploadImage },
            },
            [_vm._v(_vm._s(_vm.trans("general.cancel_upload")))]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section id="wrapper">
    <div class="row justify-content-center">
      <div class="col-12-col-md-12">
        <div class="text-center">
          <h1 class="mt-3" v-if="link.header">{{ link.header }}</h1>
          <h1 class="mt-3" v-else><b>{{ trans('link.header_goes_here') }}</b></h1>
          <img v-if="companyLogo" :src="companyLogo.image_url" alt="" class="mt-3 mb-3 img-fluid rounded">
          <div v-else style="height:0;width:20%;padding-bottom:20%;background-color:red;width:200;height:200px;">
            <div style="color:white;">
              No company logo added
            </div>
          </div>
          <h3 class="mb-3 mt-3" v-if="link.sub_header">{{ link.sub_header }}</h3>
          <h3 class="mb-3 mt-3" v-else>{{ trans('link.sub_header_goes_here') }}</h3>
        </div>
      </div>
    </div>
    <form @submit.prevent="proceed" @keydown="publicLinkForm.errors.clear($event.target.name)">
      <div class="row justify-content-center">
        <div class="col-12 col-md-4 col-lg-6">
          <a v-if="item.url !== ''"
             @click="updateBtnClicked(item)"
             :href="item.url"
             v-for="(item, index) in link.link_items"
             class="btn btn-primary btn-lg btn-block"
             role="button"
             target="_blank">
            <div class="p-3">
              <img :src="item.link_item_types.item_type_image[0].original_url" class="img-fluid float-left" :alt="item.name"/>

              <span class="text-center">{{ item.name }}</span>
              <span class="float-right">
                                <i class="fa-solid fa-chevron-right"></i>
                            </span>
            </div>
          </a>
          <h3 v-else class="text-center">{{ trans('link_item.no_active_link_items') }}</h3>
        </div>
      </div>
    </form>
    <div class="row justify-content-center mt-3">
      <div class="col-12-col-md-12">
        <h5 class="mt-3" v-if="link.footer_text">{{ link.footer_text }}</h5>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-12-col-md-12">
        <a :href="impressum_link" target="_blank"><label class="text-muted">{{
            trans('general.impressum')
          }}</label></a> |
        <a :href="data_protection_link" target="_blank"><label
            class="text-muted">{{ trans('general.data_protection') }}</label></a>
      </div>
    </div>
  </section>
</template>


<script>

export default {
  components: {},
  data() {
    return {
      publicLinkForm: new Form({}),
      link: [],
      companyLogo: {},
      impressum_link: '',
      data_protection_link: '',
      id: this.$route.params.hashed_id,
      appUrl: process.env.MIX_PUBLIC_URL
    };
  },
  mounted() {
    this.getPublicLinkData();
    this.countLoads()
  },
  computed: {},
  methods: {
    proceed() {
      if (this.id) {
        this.updateBtnClicked();
      }
    },
    countLoads() {
      this.publicLinkForm.post('/api/auth/link/load-count/' + this.id)
          .then(response => {

          })
          .catch(error => {
            helper.showErrorMsg(error);
          });
    },
    updateBtnClicked(item) {
      this.publicLinkForm.post('/api/auth/link/click-count/' + item.id)
          .then(response => {
            this.getPublicLinkData();
          })
          .catch(error => {
            helper.showErrorMsg(error);
          });
    },
    getPublicLinkData() {
      axios.get('/api/auth/link/' + this.id)
          .then(response => response.data)
          .then(response => {
            console.log(response);
            this.link = response.link;
            this.impressum_link = response.impressum;
            this.data_protection_link = response.dataProtection;
            this.companyLogo = {
              image_url: null
            };
            if (response.media && response.media.original_url) {
              this.companyLogo.image_url = response.media.original_url;
            }
          })
          .catch(error => {
            helper.showDataErrorMsg(error);
            //this.$router.push('/login');
          });
    },

  }
}
</script>

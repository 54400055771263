var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("auth.authentication")) + "\n            "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function ($event) {
                return _vm.$router.push("/home")
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home"))),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body p-4" }, [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.trans("auth.authentication"))),
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                    keydown: function ($event) {
                      return _vm.configForm.errors.clear($event.target.name)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("auth.session_lifetime") +
                                        " " +
                                        _vm.trans("auth.in_minute")
                                    ) + " "
                                  ),
                                  _c("show-tip", {
                                    attrs: {
                                      module: "auth",
                                      tip: "tip_session_lifetime",
                                      type: "field",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.configForm.session_lifetime,
                                    expression: "configForm.session_lifetime",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  value: "",
                                  name: "session_lifetime",
                                  placeholder: _vm.trans(
                                    "auth.session_lifetime"
                                  ),
                                },
                                domProps: {
                                  value: _vm.configForm.session_lifetime,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.configForm,
                                      "session_lifetime",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.configForm,
                                  "prop-name": "session_lifetime",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "" } },
                                [
                                  _c("small", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans(
                                          "auth.reset_password_token_lifetime"
                                        ) +
                                          " " +
                                          _vm.trans("auth.in_minute")
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("show-tip", {
                                    attrs: {
                                      module: "auth",
                                      tip: "tip_reset_password_token_lifetime",
                                      type: "field",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.configForm
                                        .reset_password_token_lifetime,
                                    expression:
                                      "configForm.reset_password_token_lifetime",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  value: "",
                                  name: "reset_password_token_lifetime",
                                  placeholder: _vm.trans(
                                    "auth.reset_password_token_lifetime"
                                  ),
                                },
                                domProps: {
                                  value:
                                    _vm.configForm
                                      .reset_password_token_lifetime,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.configForm,
                                      "reset_password_token_lifetime",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.configForm,
                                  "prop-name": "reset_password_token_lifetime",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.trans("auth.registration")) + " "
                                ),
                                _c("show-tip", {
                                  attrs: {
                                    module: "auth",
                                    tip: "tip_registration",
                                    type: "field",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: _vm.configForm.registration
                                      ? "success"
                                      : "default",
                                    "true-value": 1,
                                    "false-value": 0,
                                  },
                                  model: {
                                    value: _vm.configForm.registration,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "registration",
                                        $$v
                                      )
                                    },
                                    expression: "configForm.registration",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "" } },
                              [
                                _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("auth.password_strength_meter")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("show-tip", {
                                  attrs: {
                                    module: "auth",
                                    tip: "tip_password_strength_meter",
                                    type: "field",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: _vm.configForm
                                      .password_strength_meter
                                      ? "success"
                                      : "default",
                                  },
                                  model: {
                                    value:
                                      _vm.configForm.password_strength_meter,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "password_strength_meter",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "configForm.password_strength_meter",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.trans("auth.email_verification")) +
                                    " "
                                ),
                                _c("show-tip", {
                                  attrs: {
                                    module: "auth",
                                    tip: "tip_email_verification",
                                    type: "field",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: _vm.configForm.email_verification
                                      ? "success"
                                      : "default",
                                  },
                                  model: {
                                    value: _vm.configForm.email_verification,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "email_verification",
                                        $$v
                                      )
                                    },
                                    expression: "configForm.email_verification",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.trans("auth.account_approval")) +
                                    " "
                                ),
                                _c("show-tip", {
                                  attrs: {
                                    module: "auth",
                                    tip: "tip_account_approval",
                                    type: "field",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: _vm.configForm.account_approval
                                      ? "success"
                                      : "default",
                                  },
                                  model: {
                                    value: _vm.configForm.account_approval,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "account_approval",
                                        $$v
                                      )
                                    },
                                    expression: "configForm.account_approval",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("auth.terms_and_conditions")
                                  ) + " "
                                ),
                                _c("show-tip", {
                                  attrs: {
                                    module: "auth",
                                    tip: "tip_terms_and_conditions",
                                    type: "field",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: _vm.configForm.terms_and_conditions
                                      ? "success"
                                      : "default",
                                  },
                                  model: {
                                    value: _vm.configForm.terms_and_conditions,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "terms_and_conditions",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "configForm.terms_and_conditions",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.trans("auth.reset_password")) + " "
                                ),
                                _c("show-tip", {
                                  attrs: {
                                    module: "auth",
                                    tip: "tip_reset_password",
                                    type: "field",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: _vm.configForm.reset_password
                                      ? "success"
                                      : "default",
                                  },
                                  model: {
                                    value: _vm.configForm.reset_password,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "reset_password",
                                        $$v
                                      )
                                    },
                                    expression: "configForm.reset_password",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("auth.two_factor_security")
                                  ) + " "
                                ),
                                _c("show-tip", {
                                  attrs: {
                                    module: "auth",
                                    tip: "tip_two_factor_security",
                                    type: "field",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: _vm.configForm.two_factory_security
                                      ? "success"
                                      : "default",
                                  },
                                  model: {
                                    value: _vm.configForm.two_factor_security,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "two_factor_security",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "configForm.two_factor_security",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-6" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.trans("auth.lock_screen")) + " "
                                ),
                                _c("show-tip", {
                                  attrs: {
                                    module: "auth",
                                    tip: "tip_lock_screen",
                                    type: "field",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: _vm.configForm.lock_screen
                                      ? "success"
                                      : "default",
                                  },
                                  model: {
                                    value: _vm.configForm.lock_screen,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "lock_screen",
                                        $$v
                                      )
                                    },
                                    expression: "configForm.lock_screen",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _vm.configForm.lock_screen
                            ? _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans(
                                            "auth.lock_screen_timeout"
                                          ) +
                                            " " +
                                            _vm.trans("auth.in_minute")
                                        ) + " "
                                      ),
                                      _c("show-tip", {
                                        attrs: {
                                          module: "auth",
                                          tip: "tip_lock_screen_timeout",
                                          type: "field",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.configForm.lock_screen_timeout,
                                        expression:
                                          "configForm.lock_screen_timeout",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "number",
                                      value: "",
                                      name: "lock_screen_timeout",
                                      placeholder: _vm.trans(
                                        "auth.lock_screen_timeout"
                                      ),
                                    },
                                    domProps: {
                                      value: _vm.configForm.lock_screen_timeout,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.configForm,
                                          "lock_screen_timeout",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.configForm,
                                      "prop-name": "lock_screen_timeout",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.trans("auth.login_throttle")) + " "
                                ),
                                _c("show-tip", {
                                  attrs: {
                                    module: "auth",
                                    tip: "tip_login_throttle",
                                    type: "field",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: _vm.configForm.login_throttle
                                      ? "success"
                                      : "default",
                                  },
                                  model: {
                                    value: _vm.configForm.login_throttle,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "login_throttle",
                                        $$v
                                      )
                                    },
                                    expression: "configForm.login_throttle",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-6" }),
                      ]),
                      _vm._v(" "),
                      _vm.configForm.login_throttle
                        ? _c("div", [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12 col-sm-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.trans(
                                              "auth.login_throttle_attempt"
                                            )
                                          ) + " "
                                        ),
                                        _c("show-tip", {
                                          attrs: {
                                            module: "auth",
                                            tip: "tip_login_throttle_attempt",
                                            type: "field",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.configForm
                                              .login_throttle_attempt,
                                          expression:
                                            "configForm.login_throttle_attempt",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "number",
                                        value: "",
                                        name: "login_throttle_attempt",
                                        placeholder: _vm.trans(
                                          "auth.login_throttle_attempt"
                                        ),
                                      },
                                      domProps: {
                                        value:
                                          _vm.configForm.login_throttle_attempt,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.configForm,
                                            "login_throttle_attempt",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.configForm,
                                        "prop-name": "login_throttle_attempt",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-12 col-sm-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.trans(
                                              "auth.login_throttle_timeout"
                                            ) +
                                              " " +
                                              _vm.trans("auth.in_minute")
                                          ) + " "
                                        ),
                                        _c("show-tip", {
                                          attrs: {
                                            module: "auth",
                                            tip: "tip_login_throttle_timeout",
                                            type: "field",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.configForm
                                              .login_throttle_timeout,
                                          expression:
                                            "configForm.login_throttle_timeout",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "number",
                                        value: "",
                                        name: "login_throttle_timeout",
                                        placeholder: _vm.trans(
                                          "auth.login_throttle_timeout"
                                        ),
                                      },
                                      domProps: {
                                        value:
                                          _vm.configForm.login_throttle_timeout,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.configForm,
                                            "login_throttle_timeout",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.configForm,
                                        "prop-name": "login_throttle_timeout",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-sm-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          { attrs: { for: "" } },
                          [
                            _vm._v(
                              _vm._s(_vm.trans("auth.social_login")) + " "
                            ),
                            _c("show-tip", {
                              attrs: {
                                module: "auth",
                                tip: "tip_social_login",
                                type: "field",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("switches", {
                              attrs: {
                                theme: "bootstrap",
                                color: _vm.configForm.social_login
                                  ? "success"
                                  : "default",
                              },
                              model: {
                                value: _vm.configForm.social_login,
                                callback: function ($$v) {
                                  _vm.$set(_vm.configForm, "social_login", $$v)
                                },
                                expression: "configForm.social_login",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.configForm.social_login
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.configForm.providers,
                              function (provider) {
                                return _c("div", [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans("auth.provider_login", {
                                            type: provider.provider,
                                          })
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c("switches", {
                                          attrs: {
                                            name: provider.provider + "_login",
                                            theme: "bootstrap",
                                            color: provider.login
                                              ? "success"
                                              : "default",
                                          },
                                          model: {
                                            value: provider.login,
                                            callback: function ($$v) {
                                              _vm.$set(provider, "login", $$v)
                                            },
                                            expression: "provider.login",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  provider.login
                                    ? _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-sm-4" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                _c(
                                                  "label",
                                                  { attrs: { for: "" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.trans(
                                                          "auth.provider_client_id",
                                                          {
                                                            type: provider.provider,
                                                          }
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: provider.client,
                                                      expression:
                                                        "provider.client",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    value: "",
                                                    name:
                                                      provider.provider +
                                                      "_client",
                                                    placeholder: _vm.trans(
                                                      "auth.provider_client_id",
                                                      {
                                                        type: provider.provider,
                                                      }
                                                    ),
                                                  },
                                                  domProps: {
                                                    value: provider.client,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        provider,
                                                        "client",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-sm-4" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                _c(
                                                  "label",
                                                  { attrs: { for: "" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.trans(
                                                          "auth.provider_secret",
                                                          {
                                                            type: provider.provider,
                                                          }
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: provider.secret,
                                                      expression:
                                                        "provider.secret",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    value: "",
                                                    name:
                                                      provider.provider +
                                                      "secret",
                                                    placeholder: _vm.trans(
                                                      "auth.provider_secret",
                                                      {
                                                        type: provider.provider,
                                                      }
                                                    ),
                                                  },
                                                  domProps: {
                                                    value: provider.secret,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        provider,
                                                        "secret",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-12 col-sm-4" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                _c(
                                                  "label",
                                                  { attrs: { for: "" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.trans(
                                                          "auth.provider_redirect_url",
                                                          {
                                                            type: provider.provider,
                                                          }
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        provider.redirect_url,
                                                      expression:
                                                        "provider.redirect_url",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    value: "",
                                                    name:
                                                      provider.provider +
                                                      "redirect_url",
                                                    placeholder: _vm.trans(
                                                      "auth.provider_redirect_url",
                                                      {
                                                        type: provider.provider,
                                                      }
                                                    ),
                                                  },
                                                  domProps: {
                                                    value:
                                                      provider.redirect_url,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        provider,
                                                        "redirect_url",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-info waves-effect waves-light m-t-10 pull-right",
                      attrs: { type: "submit" },
                    },
                    [_vm._v(_vm._s(_vm.trans("general.save")))]
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div class="page-titles p-3 border-bottom">
      <h3 class="text-themecolor">{{ trans('link_item.link_item') }}
        <span class="card-subtitle"
              v-if="linkItems">{{ trans('general.total_result_found', {'count': linkItems.total}) }}</span>
        <span class="card-subtitle" v-else>{{ trans('general.no_result_found') }}</span>

        <sort-by class="pull-right" :order-by-options="orderByOptions" :sort-by="filterLinkItemForm.sort_by"
                 :order="filterLinkItemForm.order" @updateSortBy="value => {filterLinkItemForm.sort_by = value}"
                 @updateOrder="value => {filterLinkItemForm.order = value}"></sort-by>
        <button class="btn btn-info btn-sm pull-right m-r-10" v-if="!showFilterPanel"
                @click="showFilterPanel = !showFilterPanel"><i class="fas fa-filter"></i> <span
            class="d-none d-sm-inline">{{ trans('general.filter') }}</span></button>
        <button class="btn btn-info btn-sm pull-right m-r-10" v-if="linkItems.total && !showCreatePanel"
                @click="showCreatePanel = !showCreatePanel"><i class="fas fa-plus"></i> <span
            class="d-none d-sm-inline">{{ trans('link_item.add_new_link_item') }}</span></button>
      </h3>
    </div>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <transition name="fade">
            <div class="card border-bottom" v-if="showCreatePanel">
              <div class="card-body p-4">
                <h4 class="card-title">{{ trans('link_item.add_new_link_item') }}</h4>
                <link-item-form @completed="getLinkItems"
                           @cancel="showCreatePanel = !showCreatePanel"></link-item-form>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="card border-bottom" v-if="showFilterPanel">
              <div class="card-body p-4">
                <h4 class="card-title">{{ trans('general.filter') }}</h4>
                <div class="row">
                  <div class="col-6 col-md-3">
                    <div class="form-group">
                      <label for="">{{ trans('general.name') }}</label>
                      <input class="form-control" name="name" v-model="filterLinkItemForm.name"
                             @keyup="getLinkItems">
                    </div>
                  </div>
                </div>
                <button class="btn btn-danger btn-sm pull-right" v-if="showFilterPanel"
                        @click="showFilterPanel = !showFilterPanel">{{ trans('general.cancel') }}
                </button>
              </div>
            </div>
          </transition>
          <div class="card">
            <div class="card-body">
              <draggable
                  v-if="linkItems.total"
                  v-model="linkItems.data"
                  v-bind="dragOptions"
                  @change="updateDraggable"
                  @start="isDragging = true"
                  @end="isDragging = false"
                  handle=".sort-num"
              >
                <div v-for="(item, key) in linkItems.data">
                  <div class="card-header d-flex align-items-center">
                    <span
                        class="px-3 py-2 border mr-3 bg-white sort-num"
                        v-text="key + 1"
                    ></span>
                    <div class="d-flex flex-column mr-auto">
                     <span class="px-3 mt-2">
                      {{item.name}}
                     </span>
                    </div>

                  </div>
                </div>
              </draggable>
              <module-info v-if="!linkItems.total" module="link_item" title="module_info_title"
                           description="module_info_description" icon="check-circle">
                <div slot="btn">
                  <button class="btn btn-info btn-md" v-if="!showCreatePanel"
                          @click="showCreatePanel = !showCreatePanel"><i class="fas fa-plus"></i>
                    {{ trans('general.add_new') }}
                  </button>
                </div>
              </module-info>
              <pagination-record :page-length.sync="filterLinkItemForm.page_length" :records="linkItems"
                                 @updateRecords="getLinkItems"></pagination-record>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import linkItemForm from './form';
import sortBy from '../../components/sort-by';
import draggable from 'vuedraggable';

export default {
  components: {linkItemForm, sortBy, draggable},
  data() {
    return {
      linkItems: {
        total: 0,
        data: []
      },
      filterLinkItemForm: {
        name: '',
        sort_by: 'sort',
        page_length: helper.getConfig('page_length')
      },
      orderByOptions: [
        {
          value: 'name',
          translation: i18n.link_item.name
        }
      ],
      isDragging: false,
      showCreatePanel: false,
      showFilterPanel: false
    };
  },
  mounted() {
    if (!helper.hasPermission('access-link-item')) {
      helper.notAccessibleMsg();
      this.$router.push('/home');
    }

    this.getLinkItems();
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  methods: {
    hasPermission(permission) {
      return helper.hasPermission(permission);
    },
    updateDraggable() {
      this.linkItems.data.forEach((i, key) => {
        i.sort = key + 1;
      });

      axios.post('api/link-item/update-order', this.linkItems)
          .then(response => response.data)
          .then(response => {
            this.getLinkItems();
          }).catch(error => {
        helper.showDataErrorMsg(error);
      });
    },
    getLinkItems(page) {
      if (typeof page !== 'number') {
        page = 1;
      }
      let url = helper.getFilterURL(this.filterLinkItemForm);
      axios.get('/api/link-item?page=' + page + url)
          .then(response => response.data)
          .then(response => {
            this.linkItems = response;
          })
          .catch(error => {
            helper.showDataErrorMsg(error);
          });
    },
    editLinkItem(link) {
      this.$router.push('/link/' + link.id + '/edit');
    },
    confirmDelete(link) {
      return dialog => this.deleteLinkItem(link);
    },
    deleteLinkItem(link) {
      axios.delete('/api/link-item/' + link.id)
          .then(response => response.data)
          .then(response => {
            toastr.success(response.message);
            this.getLinkItems();
          }).catch(error => {
        helper.showDataErrorMsg(error);
      });
    },
  },
  filters: {
    moment(date) {
      return helper.formatDate(date);
    }
  },
  watch: {
    filterLinkItemForm: {
      handler(val) {
        setTimeout(() => {
          this.getLinkItems();
        }, 500)
      },
      deep: true
    }
  }
}
</script>
<style scoped>
.ghost {
  opacity: 0.5;
}

.sort-num {
  cursor: move;
}
</style>
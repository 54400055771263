var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "topbar" }, [
    _c(
      "nav",
      { staticClass: "navbar top-navbar navbar-expand-md navbar-light" },
      [
        _c(
          "div",
          { staticClass: "navbar-header" },
          [
            _c(
              "router-link",
              { staticClass: "navbar-brand", attrs: { to: "/" } },
              [
                _c("b", [
                  _c("img", {
                    staticClass: "dark-logo",
                    attrs: { src: _vm.getSidebarLogo, alt: "" },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "light-logo",
                    attrs: { src: _vm.getSidebarLogo, alt: "" },
                  }),
                ]),
                _vm._v(" "),
                _c("span", [
                  _c("img", {
                    staticClass: "dark-logo",
                    attrs: { src: _vm.getMainLogo, alt: "" },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "light-logo",
                    attrs: { src: _vm.getMainLogo, alt: "" },
                  }),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "navbar-collapse" }, [
          _c("ul", { staticClass: "navbar-nav mr-auto mt-md-0 " }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.right",
                    value: _vm.trans("general.toggle_sidebar"),
                    expression: "trans('general.toggle_sidebar')",
                    modifiers: { right: true },
                  },
                ],
                staticClass: "nav-item",
              },
              [_vm._m(1)]
            ),
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "navbar-nav my-lg-0" }, [
            _vm.getConfig("todo") && _vm.hasPermission("access-todo")
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.bottom",
                        value: _vm.trans("todo.todo"),
                        expression: "trans('todo.todo')",
                        modifiers: { bottom: true },
                      },
                    ],
                    staticClass: "nav-item",
                  },
                  [
                    _c(
                      "router-link",
                      { staticClass: "nav-link", attrs: { to: "/todo" } },
                      [_c("i", { staticClass: "far fa-check-circle" })]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.getConfig("message") && _vm.hasPermission("access-message")
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.bottom",
                        value: _vm.trans("message.message"),
                        expression: "trans('message.message')",
                        modifiers: { bottom: true },
                      },
                    ],
                    staticClass: "nav-item",
                  },
                  [
                    _c(
                      "router-link",
                      { staticClass: "nav-link", attrs: { to: "/message" } },
                      [_c("i", { staticClass: "fas fa-envelope" })]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("access-configuration")
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.bottom",
                        value: _vm.trans("configuration.configuration"),
                        expression: "trans('configuration.configuration')",
                        modifiers: { bottom: true },
                      },
                    ],
                    staticClass: "nav-item",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: { to: "/configuration" },
                      },
                      [_c("i", { staticClass: "fas fa-cogs" })]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("li", { staticClass: "nav-item dropdown" }, [
              _c(
                "a",
                {
                  staticClass:
                    "nav-link dropdown-toggle text-muted waves-effect waves-dark",
                  attrs: {
                    href: "",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false",
                  },
                },
                [
                  _c("img", {
                    staticClass: "profile-pic",
                    attrs: { src: _vm.getAuthUser("avatar"), alt: "user" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    "dropdown-menu",
                    _vm.getConfig("user_direction") != "rtl"
                      ? "dropdown-menu-right"
                      : "",
                  ],
                },
                [
                  _c("ul", { staticClass: "dropdown-user" }, [
                    _c("li", [
                      _c("div", { staticClass: "dw-user-box text-center" }, [
                        _c("div", { staticClass: "u-img" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.getAuthUser("avatar"),
                              alt: "user",
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "u-text" },
                          [
                            _c("h4", [
                              _vm._v(_vm._s(_vm.getAuthUser("full_name"))),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "text-muted" }, [
                              _vm._v(_vm._s(_vm.getAuthUser("email"))),
                            ]),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "btn btn-rounded btn-danger btn-sm",
                                attrs: { to: "/profile" },
                              },
                              [_vm._v(_vm._s(_vm.trans("user.view_profile")))]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", {
                      staticClass: "divider",
                      attrs: { role: "separator" },
                    }),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/change-password" } },
                          [
                            _c("i", { staticClass: "fas fa-key" }),
                            _vm._v(
                              " " + _vm._s(_vm.trans("user.change_password"))
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("li", {
                      staticClass: "divider",
                      attrs: { role: "separator" },
                    }),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.logout.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fas fa-power-off" }),
                          _vm._v(" " + _vm._s(_vm.trans("auth.logout"))),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item" }, [
      _c(
        "a",
        {
          staticClass:
            "nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark",
          attrs: { href: "javascript:void(0)" },
        },
        [_c("i", { staticClass: "fas fa-bars" })]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "nav-link sidebartoggler hidden-sm-down text-muted waves-effect waves-dark",
        attrs: { href: "javascript:void(0)" },
      },
      [_c("i", { staticClass: "icon-arrow-left-circle fas" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
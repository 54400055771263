<template>
  <div>
    <div class="page-titles p-3 border-bottom">
      <h3 class="text-themecolor">{{ trans('link_item_type.edit_link_item_type') }}
        <button class="btn btn-info btn-sm pull-right" @click="$router.push('/link-item-type')"><i
            class="fas fa-check-circle"></i> <span class="d-none d-sm-inline">{{ trans('link_item_type.link_item_type') }}</span>
        </button>
      </h3>
    </div>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body p-4">
              <h4 class="card-title">{{ trans('link_item_type.edit_link_item_type') }}</h4>
              <link-item-type-form :id="id"></link-item-type-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import linkItemTypeForm from './form';

export default {
  components: {linkItemTypeForm},
  data() {
    return {
      id: this.$route.params.id
    }
  },
  mounted() {
    if (!helper.hasPermission('access-link-item-type')) {
      helper.notAccessibleMsg();
      this.$router.push('/home');
    }
  }
}
</script>

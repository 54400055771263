var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(
          _vm._s(_vm.trans("configuration.basic_configuration")) +
            "\n            "
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function ($event) {
                return _vm.$router.push("/home")
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home"))),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body p-4" },
              [
                _c("show-tip", {
                  attrs: {
                    module: "configuration",
                    tip: "tip_basic_configuration",
                  },
                }),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                      keydown: function ($event) {
                        return _vm.configForm.errors.clear($event.target.name)
                      },
                    },
                  },
                  [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("general.company"))),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(_vm.trans("configuration.company_name"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.company_name,
                                  expression: "configForm.company_name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "company_name",
                                placeholder: _vm.trans(
                                  "configuration.company_name"
                                ),
                              },
                              domProps: { value: _vm.configForm.company_name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "company_name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "company_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("configuration.contact_person")
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.contact_person,
                                  expression: "configForm.contact_person",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "contact_person",
                                placeholder: _vm.trans(
                                  "configuration.contact_person"
                                ),
                              },
                              domProps: {
                                value: _vm.configForm.contact_person,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "contact_person",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "contact_person",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("configuration.contact"))),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("configuration.address_line_1")
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.address_line_1,
                                  expression: "configForm.address_line_1",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "address_line_1",
                                placeholder: _vm.trans(
                                  "configuration.address_line_1"
                                ),
                              },
                              domProps: {
                                value: _vm.configForm.address_line_1,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "address_line_1",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "address_line_1",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("configuration.address_line_2")
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.address_line_2,
                                  expression: "configForm.address_line_2",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "address_line_2",
                                placeholder: _vm.trans(
                                  "configuration.address_line_2"
                                ),
                              },
                              domProps: {
                                value: _vm.configForm.address_line_2,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "address_line_2",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "address_line_2",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("configuration.city"))),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.city,
                                  expression: "configForm.city",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "city",
                                placeholder: _vm.trans("configuration.city"),
                              },
                              domProps: { value: _vm.configForm.city },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "city",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "city",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("configuration.state"))),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.state,
                                  expression: "configForm.state",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "state",
                                placeholder: _vm.trans("configuration.state"),
                              },
                              domProps: { value: _vm.configForm.state },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "state",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "state",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(_vm.trans("configuration.zipcode"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.zipcode,
                                  expression: "configForm.zipcode",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "zipcode",
                                placeholder: _vm.trans("configuration.zipcode"),
                              },
                              domProps: { value: _vm.configForm.zipcode },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "zipcode",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "zipcode",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(_vm.trans("configuration.country"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.country,
                                  expression: "configForm.country",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "country",
                                placeholder: _vm.trans("configuration.country"),
                              },
                              domProps: { value: _vm.configForm.country },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "country",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "country",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("configuration.phone"))),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.phone,
                                  expression: "configForm.phone",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "phone",
                                placeholder: _vm.trans("configuration.phone"),
                              },
                              domProps: { value: _vm.configForm.phone },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "phone",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "phone",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("configuration.fax"))),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.fax,
                                  expression: "configForm.fax",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "fax",
                                placeholder: _vm.trans("configuration.fax"),
                              },
                              domProps: { value: _vm.configForm.fax },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "fax",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "fax",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("configuration.email"))),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.email,
                                  expression: "configForm.email",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "email",
                                placeholder: _vm.trans("configuration.email"),
                              },
                              domProps: { value: _vm.configForm.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "email",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "email",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(_vm.trans("configuration.website"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.website,
                                  expression: "configForm.website",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "website",
                                placeholder: _vm.trans("configuration.website"),
                              },
                              domProps: { value: _vm.configForm.website },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "website",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "website",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-info waves-effect waves-light m-t-10 pull-right",
                        attrs: { type: "submit" },
                      },
                      [_vm._v(_vm._s(_vm.trans("general.save")))]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
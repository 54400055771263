<template>
  <form @submit.prevent="proceed" @keydown="linkItemForm.errors.clear($event.target.name)">
    <div class="row">
      <div class="col-12 col-md-4 col-sm-12">
        <div class="form-group">
          <label for="">{{ trans('link_item.name') }}</label>
          <input class="form-control" type="text" value="" v-model="linkItemForm.name" name="name"
                 :placeholder="trans('general.name')">
          <show-error :form-name="linkItemForm" prop-name="name"></show-error>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-info waves-effect waves-light pull-right">
      <span v-if="id">{{ trans('general.update') }}</span>
      <span v-else>{{ trans('general.save') }}</span>
    </button>
    <router-link to="/link" class="btn btn-danger waves-effect waves-light pull-right m-r-10" v-show="id">
      {{ trans('general.cancel') }}
    </router-link>
    <button v-if="!id" type="button" class="btn btn-danger waves-effect waves-light pull-right m-r-10"
            @click="$emit('cancel')">{{ trans('general.cancel') }}
    </button>
  </form>
</template>


<script>

export default {
  components: {},
  data() {
    return {
      linkItemForm: new Form({
        name: '',
      })
    };
  },
  props: ['id'],
  mounted() {
    if (this.id) {
      this.getLink();
    }
  },
  methods: {
    proceed() {
      if (this.id) {
        this.updateLinkItem();
      } else {
        this.storeLinkItem();
      }
    },
    getLink() {
      axios.get('/api/link-item/' + this.id)
          .then(response => response.data)
          .then(response => {
            this.linkItemForm.name = response.name;
          })
          .catch(error => {
            helper.showDataErrorMsg(error);
            this.$router.push('/link');
          });
    },
    storeLinkItem() {
      this.linkItemForm.post('/api/link-item')
          .then(response => {
            toastr.success(response.message);
            this.selectedReasons = null;
            this.$emit('completed')
          })
          .catch(error => {
            helper.showErrorMsg(error);
          });
    },
    updateLinkItem() {
      this.linkItemForm.put('/api/link-item/' + this.id)
          .then(response => {
            toastr.success(response.message);
            this.$router.push('/link-item');
          })
          .catch(error => {
            helper.showErrorMsg(error);
          });
    }
  }
}
</script>

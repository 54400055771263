var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("user.user")) + " \n            "),
        _vm.user.profile
          ? _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(
                _vm._s(
                  _vm.user.profile.first_name + " " + _vm.user.profile.last_name
                ) +
                  " (" +
                  _vm._s(_vm.user.email) +
                  ")"
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function ($event) {
                return _vm.$router.push("/user")
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-list" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("user.user_list"))),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "col-12 col-sm-8" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body p-4" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("user-sidebar", {
                      attrs: { menu: "social", id: _vm.id },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-9 col-sm-9" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.trans("user.social"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submit.apply(null, arguments)
                            },
                            keydown: function ($event) {
                              return _vm.userForm.errors.clear(
                                $event.target.name
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("user.sm_profile", {
                                      name: "Facebook",
                                    })
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.userForm.facebook_profile,
                                    expression: "userForm.facebook_profile",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  value: "",
                                  name: "facebook_profile",
                                  placeholder: _vm.trans("user.sm_profile", {
                                    name: "Facebook",
                                  }),
                                },
                                domProps: {
                                  value: _vm.userForm.facebook_profile,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.userForm,
                                      "facebook_profile",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.userForm,
                                  "prop-name": "facebook_profile",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("user.sm_profile", {
                                      name: "Twitter",
                                    })
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.userForm.twitter_profile,
                                    expression: "userForm.twitter_profile",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  value: "",
                                  name: "twitter_profile",
                                  placeholder: _vm.trans("user.sm_profile", {
                                    name: "Twitter",
                                  }),
                                },
                                domProps: {
                                  value: _vm.userForm.twitter_profile,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.userForm,
                                      "twitter_profile",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.userForm,
                                  "prop-name": "twitter_profile",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("user.sm_profile", {
                                      name: "LinkedIn",
                                    })
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.userForm.linkedin_profile,
                                    expression: "userForm.linkedin_profile",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  value: "",
                                  name: "linkedin_profile",
                                  placeholder: _vm.trans("user.sm_profile", {
                                    name: "LinkedIn",
                                  }),
                                },
                                domProps: {
                                  value: _vm.userForm.linkedin_profile,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.userForm,
                                      "linkedin_profile",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.userForm,
                                  "prop-name": "linkedin_profile",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("user.sm_profile", {
                                      name: "Google Plus",
                                    })
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.userForm.google_plus_profile,
                                    expression: "userForm.google_plus_profile",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  value: "",
                                  name: "google_plus_profile",
                                  placeholder: _vm.trans("user.sm_profile", {
                                    name: "Google Plus",
                                  }),
                                },
                                domProps: {
                                  value: _vm.userForm.google_plus_profile,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.userForm,
                                      "google_plus_profile",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.userForm,
                                  "prop-name": "google_plus_profile",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-info waves-effect waves-light m-t-10",
                              attrs: { type: "submit" },
                            },
                            [_vm._v(_vm._s(_vm.trans("general.save")))]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("user-summary", { attrs: { user: _vm.user } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
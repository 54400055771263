var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.proceed.apply(null, arguments)
          },
          keydown: function ($event) {
            return _vm.linkItemTypeForm.errors.clear($event.target.name)
          },
        },
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-4 col-sm-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.trans("general.name"))),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.linkItemTypeForm.name,
                      expression: "linkItemTypeForm.name",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    value: "",
                    name: "name",
                    placeholder: _vm.trans("general.name"),
                  },
                  domProps: { value: _vm.linkItemTypeForm.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.linkItemTypeForm,
                        "name",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: {
                    "form-name": _vm.linkItemTypeForm,
                    "prop-name": "name",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-4 col-sm-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.trans("general.bg_color"))),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("v-swatches", {
                  attrs: {
                    swatches: _vm.swatches,
                    "row-length": "5",
                    "popover-x": "left",
                  },
                  model: {
                    value: _vm.linkItemTypeForm.bg_color,
                    callback: function ($$v) {
                      _vm.$set(_vm.linkItemTypeForm, "bg_color", $$v)
                    },
                    expression: "linkItemTypeForm.bg_color",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-4 col-sm-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.trans("general.image"))),
                ]),
                _vm._v(" "),
                _c("image-upload", {
                  ref: "imageUpload",
                  attrs: {
                    id: _vm.id,
                    "max-size": 1000000,
                    "min-size": 10,
                    "image-url": _vm.linkItemTypeForm.image_url || _vm.imageUrl,
                  },
                  on: {
                    fileSelected: _vm.onFileSelected,
                    fileRemoved: _vm.onFileRemoved,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn btn-info waves-effect waves-light pull-right mt-5",
            attrs: { type: "submit" },
          },
          [
            _vm.id
              ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
              : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))]),
          ]
        ),
        _vm._v(" "),
        !_vm.id
          ? _c(
              "button",
              {
                staticClass:
                  "btn btn-danger waves-effect waves-light pull-right m-r-10 mt-5",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("cancel")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.trans("general.cancel")) + "\n    ")]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "module-info" },
    [
      _vm.icon
        ? _c("p", { staticClass: "text-muted" }, [
            _c("i", { class: ["fa", "fa-" + _vm.icon, "fa-3x"] }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.title
        ? _c("h2", {
            domProps: {
              textContent: _vm._s(_vm.trans(_vm.module + "." + _vm.title)),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.description
        ? _c("p", {
            staticClass: "text-themecolor",
            domProps: {
              textContent: _vm._s(
                _vm.trans(_vm.module + "." + _vm.description)
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("btn"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <form @submit.prevent="proceed" @keydown="linkForm.errors.clear($event.target.name)">
      <div class="row">
        <div class="col-12 col-md-4 col-sm-12">
          <div class="form-group">
            <label for="">{{ trans('general.name') }}</label>
            <input class="form-control" type="text" value="" v-model="linkForm.name" name="name"
                   :placeholder="trans('general.name')">
            <show-error :form-name="linkForm" prop-name="name"></show-error>
          </div>
        </div>
        <div class="col-12 col-md-4 col-sm-12">
          <label for="">{{ trans('link.link_url') }}</label>
          <div class="input-group" v-if="linkForm.hashed_id !== ''">
            <input
                class="form-control"
                v-on:focus="$event.target.select()"
                ref="clone"
                readonly
                :value="appLinkUrl + linkForm.hashed_id"/>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" @click.prevent="copy"><i class="fa-solid fa-copy"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-sm-12" v-if="id">
          <div class="form-group">
            <label for="">{{ trans('general.header') }}</label>
            <input class="form-control" type="text" value="" v-model="linkForm.header" name="header"
                   :placeholder="trans('general.header_here')">
            <show-error :form-name="linkForm" prop-name="header"></show-error>
          </div>
        </div>
        <div class="col-12 col-md-4 col-sm-12" v-if="id">
          <div class="form-group">
            <label for="">{{ trans('general.sub_header') }}</label>
            <input class="form-control" type="text" value="" v-model="linkForm.sub_header" name="sub_header"
                   :placeholder="trans('general.sub_header_here')">
            <show-error :form-name="linkForm" prop-name="sub_header"></show-error>
          </div>
        </div>
        <div class="col-12 col-md-4 col-sm-12" v-if="id">
          <div class="form-group">
            <label for="">{{ trans('general.footer_text') }}</label>
            <input class="form-control" type="text" value="" v-model="linkForm.footer_text" name="footer_text"
                   :placeholder="trans('general.footer_text_here')">
            <show-error :form-name="linkForm" prop-name="footer_text"></show-error>
          </div>
        </div>
<!--        <div class="col-12 col-md-4 col-sm-12" v-if="id">
          <div class="form-group">
            <h4 class="card-title">{{ trans('general.image') }}</h4>
            <image-upload
                ref="imageUpload"
                :id="id"
                :max-size="1000000"
                :min-size="10"
                :image-url="linkForm.image_url || imageUrl"
                @fileSelected="onFileSelected"
                @fileRemoved="onFileRemoved"
            ></image-upload>
          </div>
        </div>-->
      </div>
      <hr>
      <div class="row mt-3 justify-content-md-center" v-if="linkForm.link_items.length > 0 && id">
        <div class="col-12 col-md-8 col-sm-12">
          <show-tip module="link" tip="tip_link_url_at_least_one_active"></show-tip>
          <draggable
              v-model="linkForm.link_items"
              v-bind="dragOptions"
              @change="updateDraggable"
              @start="isDragging = true"
              @end="isDragging = false"
              handle=".sort-num">
            <div v-for="(item, key) in linkForm.link_items">
              <div class="card-header d-flex align-items-center">
                    <span
                        class="px-3 py-2 border mr-3 bg-white sort-num"
                        v-text="key + 1"
                    ></span>
                <span
                    class="px-3 py-2 border mr-6" v-if="item.link_item_types">
                <img :src="getFirstMediaUrl(item.link_item_types)" :alt="item.link_item_types.name"
                     class="img-responsive mr-5"
                     style="max-width:35px; max-height: 35px"/>
              </span>
                <div class="d-flex flex-column mr-auto">
                  <div class="form-inline">
                    <label class="mr-3">{{ trans('general.url') }}:</label>
                    <input class="form-control" type="text" v-model="item.url"
                           name="url"
                           :placeholder="item.name + ' ' + trans('general.url')">
                    <show-error :form-name="linkForm"
                                :prop-name="'link_items.' + key + '.url'"></show-error>
                  </div>
                </div>
                <div class="d-flex flex-column mr-auto">
                  <switches v-model="linkForm.link_items[key].active" theme="bootstrap"
                            :color="linkForm.link_items[key].active ? 'success' : 'default'"
                            :disabled="linkForm.link_items[key].disabled"
                  ></switches>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
      <button type="submit" class="btn btn-info waves-effect waves-light pull-right mt-3">
        <span v-if="id">{{ trans('general.update') }}</span>
        <span v-else>{{ trans('general.save') }}</span>
      </button>
      <router-link to="/link" class="btn btn-danger waves-effect waves-light pull-right m-r-10 mt-3" v-show="id">
        {{ trans('general.cancel') }}
      </router-link>
      <button v-if="!id" type="button" class="btn btn-danger waves-effect waves-light pull-right m-r-10 mt-3"
              @click="$emit('cancel')">{{ trans('general.cancel') }}
      </button>
    </form>
  </div>
</template>


<script>
import draggable from 'vuedraggable';
import switches from 'vue-switches';
import imageUpload from '../../components/image-upload';

export default {
  components: {draggable, switches, imageUpload},
  data() {
    return {
      linkForm: new Form({
        name: '',
        public_url: '',
        hashed_id: '',
        link_items: [
          disabled => 0
        ],
        header: 'Example header',
        sub_header: 'Example sub-header',
        footer_text: '',
        //image: '',
        //image_url: ''
      }),
      isDragging: false,
      isDisabled: false,
      appLinkUrl: process.env.MIX_PUBLIC_LINK_URL
    };
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    /*imageUrl: {
      type: String,
      default: ''
    }*/
  },
  mounted() {
    if (this.id) {
      this.getLink();
    }
  },
  computed: {
    getFirstMediaUrl() {
      return function (linkItemType) {
        if (linkItemType.media && linkItemType.media.length > 0) {
          const url = linkItemType.media[0].original_url;
          return url.startsWith('http') ? url : '/' + url;
        } else {
          return '';
        }
      };
    },
    dragOptions() {
      return {
        animation: 200,
        group: "",
        disabled: false,
        ghostClass: "ghost"
      }
    },
  },
  methods: {
    proceed() {
      if (this.id) {
        this.updateLink();
      } else {
        this.storeLink();
      }
    },
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
      toastr.success(this.trans('general.copied'));
    },
    getLink() {
      axios.get('/api/link/' + this.id)
          .then(response => response.data)
          .then(response => {
              console.log(response);
            /* this.linkForm.name = response[0].name;
            this.linkForm.hashed_id = response[0].hashed_id;
            this.linkForm.link_items = response[0].link_items;
            this.linkForm.header = response[0].header;
            this.linkForm.sub_header = response[0].sub_header;
            this.linkForm.footer_text = response[0].footer_text;
            /*if (response[1] && response[1].original_url) {
              this.linkForm.image = response[1].original_url;
              this.linkForm.image_url = response[1].original_url;
            }*/
            this.linkForm.name = response.name;
            this.linkForm.hashed_id = response.hashed_id;
            this.linkForm.link_items = response.link_items;
            this.linkForm.header = response.header;
            this.linkForm.sub_header = response.sub_header;
            this.linkForm.footer_text = response.footer_text;
          })
          .catch(error => {
            helper.showDataErrorMsg(error);
            //this.$router.push('/link');
          });
    },
    storeLink() {
      this.linkForm.post('/api/link')
          .then(response => {
            toastr.success(response.message);
            this.selectedReasons = null;
            this.$emit('completed')
          })
          .catch(error => {
            helper.showErrorMsg(error);
          });
    },
    /*updateLink() {
      let sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl;
      let count = [];
      this.linkForm.link_items.map((item, index) => {
        this.linkForm.link_items[index].url = sanitizeUrl(item.url);
        if (item.active === 1 || item.active === true) {
          count.push(item.active);
        }
      })
      if (count.length < 1) {
        return toastr.error(i18n.link_item.at_least_one);
      }
      let formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('name', this.linkForm.name);
      formData.append('public_url', this.linkForm.public_url);
      formData.append('hashed_id', this.linkForm.hashed_id);
      formData.append('header', this.linkForm.header);
      formData.append('sub_header', this.linkForm.sub_header);
      formData.append('image', this.linkForm.image);
      formData.append('image_url', this.linkForm.image_url);
      formData.append('link_items', JSON.stringify(this.linkForm.link_items));
      axios.post(`/api/link/${this.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            toastr.success(response.message);
            this.getLink();
            this.$router.push('/link');
          })
          .catch(error => {
            helper.showErrorMsg(error);
          });
    },*/
    updateLink() {
      this.linkForm.put('/api/link/' + this.id)
          .then(response => {
            toastr.success(response.message);
            this.$router.push('/link');
          })
          .catch(error => {
            helper.showErrorMsg(error);
          });
    },
    updateDraggable() {
      this.linkForm.link_items.forEach((i, key) => {
        i.sort = key + 1;
      });
    },
    /* onFileSelected(file) {
       this.linkForm.image = file;
       this.linkForm.image_url = URL.createObjectURL(file);
     },
     onFileRemoved() {
       this.linkForm.image = null;
       this.linkForm.image_url = null;
     },*/
  },
  watch: {
    'linkForm.link_items': {
      handler: function (newVal, oldVal) {
        if (Array.isArray(newVal)) {
          let activeItems = newVal.filter(i => i.active);

          if (activeItems.length > 5) {
            for (let i = 0; i < newVal.length; i++) {
              if (!newVal[i].active) {
                newVal[i].disabled = true;
              }
            }
            toastr.error(i18n.link_item.active_item_limit_front);
          } else {
            for (let i = 0; i < newVal.length; i++) {
              if (newVal[i].disabled) {
                newVal[i].disabled = false;
              }
            }
          }

          // only allow 5 items to be active, rest should be disabled right away when 5th item is activated
          if (activeItems.length === 5) {
            for (let i = 0; i < newVal.length; i++) {
              if (!newVal[i].active && !newVal[i].disabled) {
                newVal[i].disabled = true;
              }
            }
          }
        }
      },
      deep: true
    }
  }


}
</script>
<style scoped>
.ghost {
  opacity: 0.5;
}

.sort-num {
  cursor: move;
}
</style>
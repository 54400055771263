var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "wrapper" } }, [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-12-col-md-12" }, [
        _c("div", { staticClass: "text-center" }, [
          _vm.link.header
            ? _c("h1", { staticClass: "mt-3" }, [
                _vm._v(_vm._s(_vm.link.header)),
              ])
            : _c("h1", { staticClass: "mt-3" }, [
                _c("b", [_vm._v(_vm._s(_vm.trans("link.header_goes_here")))]),
              ]),
          _vm._v(" "),
          _vm.companyLogo
            ? _c("img", {
                staticClass: "mt-3 mb-3 img-fluid rounded",
                attrs: { src: _vm.companyLogo.image_url, alt: "" },
              })
            : _c(
                "div",
                {
                  staticStyle: {
                    height: "200px",
                    width: "200",
                    "padding-bottom": "20%",
                    "background-color": "red",
                  },
                },
                [
                  _c("div", { staticStyle: { color: "white" } }, [
                    _vm._v("\n            No company logo added\n          "),
                  ]),
                ]
              ),
          _vm._v(" "),
          _vm.link.sub_header
            ? _c("h3", { staticClass: "mb-3 mt-3" }, [
                _vm._v(_vm._s(_vm.link.sub_header)),
              ])
            : _c("h3", { staticClass: "mb-3 mt-3" }, [
                _vm._v(_vm._s(_vm.trans("link.sub_header_goes_here"))),
              ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.proceed.apply(null, arguments)
          },
          keydown: function ($event) {
            return _vm.publicLinkForm.errors.clear($event.target.name)
          },
        },
      },
      [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-4 col-lg-6" },
            _vm._l(_vm.link.link_items, function (item, index) {
              return item.url !== ""
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary btn-lg btn-block",
                      attrs: {
                        href: item.url,
                        role: "button",
                        target: "_blank",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateBtnClicked(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "p-3" }, [
                        _c("img", {
                          staticClass: "img-fluid float-left",
                          attrs: {
                            src: item.link_item_types.item_type_image[0]
                              .original_url,
                            alt: item.name,
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _vm._v(" "),
                        _vm._m(0, true),
                      ]),
                    ]
                  )
                : _c("h3", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.trans("link_item.no_active_link_items"))),
                  ])
            }),
            0
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row justify-content-center mt-3" }, [
      _c("div", { staticClass: "col-12-col-md-12" }, [
        _vm.link.footer_text
          ? _c("h5", { staticClass: "mt-3" }, [
              _vm._v(_vm._s(_vm.link.footer_text)),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row justify-content-center mt-3" }, [
      _c("div", { staticClass: "col-12-col-md-12" }, [
        _c("a", { attrs: { href: _vm.impressum_link, target: "_blank" } }, [
          _c("label", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.trans("general.impressum"))),
          ]),
        ]),
        _vm._v(" |\n      "),
        _c(
          "a",
          { attrs: { href: _vm.data_protection_link, target: "_blank" } },
          [
            _c("label", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.trans("general.data_protection"))),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "float-right" }, [
      _c("i", { staticClass: "fa-solid fa-chevron-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
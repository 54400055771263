var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(
          _vm._s(_vm.trans("configuration.system_configuration")) + "\n      "
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function ($event) {
                return _vm.$router.push("/home")
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home"))),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body p-4" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(
                    _vm._s(_vm.trans("configuration.system_configuration"))
                  ),
                ]),
                _vm._v(" "),
                _c("show-tip", {
                  attrs: {
                    module: "configuration",
                    tip: "tip_system_configuration",
                  },
                }),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                      keydown: function ($event) {
                        return _vm.configForm.errors.clear($event.target.name)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 col-sm-6" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.color_theme")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.configForm.color_theme,
                                        expression: "configForm.color_theme",
                                      },
                                    ],
                                    staticClass: "custom-select col-12",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.configForm,
                                          "color_theme",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.systemConfigVariables.color_themes,
                                    function (option) {
                                      return _c(
                                        "option",
                                        { domProps: { value: option.value } },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(option.text) +
                                              "\n                          "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.configForm,
                                    "prop-name": "color_theme",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.trans("configuration.direction"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.configForm.direction,
                                        expression: "configForm.direction",
                                      },
                                    ],
                                    staticClass: "custom-select col-12",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.configForm,
                                          "direction",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.systemConfigVariables.directions,
                                    function (option) {
                                      return _c(
                                        "option",
                                        { domProps: { value: option.value } },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(option.text) +
                                              "\n                          "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.configForm,
                                    "prop-name": "direction",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.trans("configuration.sidebar"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.configForm.sidebar,
                                        expression: "configForm.sidebar",
                                      },
                                    ],
                                    staticClass: "custom-select col-12",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.configForm,
                                          "sidebar",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.systemConfigVariables.sidebar,
                                    function (option) {
                                      return _c(
                                        "option",
                                        { domProps: { value: option.value } },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(option.text) +
                                              "\n                          "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.configForm,
                                    "prop-name": "sidebar",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.date_format")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.configForm.date_format,
                                        expression: "configForm.date_format",
                                      },
                                    ],
                                    staticClass: "custom-select col-12",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.configForm,
                                          "date_format",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.systemConfigVariables.date_formats,
                                    function (option) {
                                      return _c(
                                        "option",
                                        { domProps: { value: option.value } },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(option.text) +
                                              "\n                          "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.configForm,
                                    "prop-name": "date_format",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.time_format")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.configForm.time_format,
                                        expression: "configForm.time_format",
                                      },
                                    ],
                                    staticClass: "custom-select col-12",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.configForm,
                                          "time_format",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.systemConfigVariables.time_formats,
                                    function (option) {
                                      return _c(
                                        "option",
                                        { domProps: { value: option.value } },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(option.text) +
                                              "\n                          "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.configForm,
                                    "prop-name": "time_format",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans(
                                        "configuration.notification_position"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.configForm.notification_position,
                                        expression:
                                          "configForm.notification_position",
                                      },
                                    ],
                                    staticClass: "custom-select col-12",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.configForm,
                                          "notification_position",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.systemConfigVariables
                                      .notification_positions,
                                    function (option) {
                                      return _c(
                                        "option",
                                        { domProps: { value: option.value } },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(option.text) +
                                              "\n                          "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.configForm,
                                    "prop-name": "notification_position",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.getConfig("multilingual")
                            ? _c("div", { staticClass: "col-12 col-sm-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(
                                        _vm._s(_vm.trans("locale.locale"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.configForm.locale,
                                            expression: "configForm.locale",
                                          },
                                        ],
                                        staticClass: "custom-select col-12",
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.configForm,
                                              "locale",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        _vm.systemConfigVariables.locales,
                                        function (option) {
                                          return _c(
                                            "option",
                                            {
                                              domProps: { value: option.value },
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(option.text) +
                                                  "\n                          "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.configForm,
                                        "prop-name": "locale",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.trans("configuration.timezone"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.configForm.timezone,
                                        expression: "configForm.timezone",
                                      },
                                    ],
                                    staticClass: "custom-select col-12",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.configForm,
                                          "timezone",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.systemConfigVariables.timezones,
                                    function (option) {
                                      return _c(
                                        "option",
                                        { domProps: { value: option.value } },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(option.text) +
                                              "\n                          "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.configForm,
                                    "prop-name": "timezone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.page_length")
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.configForm.page_length,
                                        expression: "configForm.page_length",
                                      },
                                    ],
                                    staticClass: "custom-select col-12",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.configForm,
                                          "page_length",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.getConfig("pagination"),
                                    function (option) {
                                      return _c(
                                        "option",
                                        { domProps: { value: option } },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                option +
                                                  " " +
                                                  _vm.trans("general.per_page")
                                              ) +
                                              "\n                          "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.configForm,
                                    "prop-name": "page_length",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(_vm.trans("configuration.video_url"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.video_url,
                                  expression: "configForm.video_url",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "url",
                                value: "",
                                name: "video_url",
                                placeholder: _vm.trans(
                                  "configuration.video_url"
                                ),
                              },
                              domProps: { value: _vm.configForm.video_url },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "video_url",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "video_url",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(_vm.trans("configuration.footer_credit"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.footer_credit,
                                  expression: "configForm.footer_credit",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "footer_credit",
                                placeholder: _vm.trans(
                                  "configuration.footer_credit"
                                ),
                              },
                              domProps: { value: _vm.configForm.footer_credit },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "footer_credit",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "footer_credit",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-6" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                { attrs: { for: "" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.trans("configuration.mode")) +
                                      "\n                          "
                                  ),
                                  _c("show-tip", {
                                    attrs: {
                                      type: "field",
                                      module: "configuration",
                                      tip: "tip_mode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: _vm.configForm.mode
                                        ? "success"
                                        : "default",
                                    },
                                    model: {
                                      value: _vm.configForm.mode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.configForm, "mode", $$v)
                                      },
                                      expression: "configForm.mode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                { attrs: { for: "" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.trans("configuration.https")) +
                                      "\n                          "
                                  ),
                                  _c("show-tip", {
                                    attrs: {
                                      type: "field",
                                      module: "configuration",
                                      tip: "tip_https",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: _vm.configForm.https
                                        ? "success"
                                        : "default",
                                    },
                                    model: {
                                      value: _vm.configForm.https,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.configForm, "https", $$v)
                                      },
                                      expression: "configForm.https",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                { attrs: { for: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.error_display")
                                    ) + "\n                          "
                                  ),
                                  _c("show-tip", {
                                    attrs: {
                                      type: "field",
                                      module: "configuration",
                                      tip: "tip_error_log",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: _vm.configForm.error_display
                                        ? "success"
                                        : "default",
                                    },
                                    model: {
                                      value: _vm.configForm.error_display,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "error_display",
                                          $$v
                                        )
                                      },
                                      expression: "configForm.error_display",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                { attrs: { for: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.multilingual")
                                    ) + "\n                          "
                                  ),
                                  _c("show-tip", {
                                    attrs: {
                                      type: "field",
                                      module: "configuration",
                                      tip: "tip_multilingual",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: _vm.configForm.multilingual
                                        ? "success"
                                        : "default",
                                    },
                                    model: {
                                      value: _vm.configForm.multilingual,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "multilingual",
                                          $$v
                                        )
                                      },
                                      expression: "configForm.multilingual",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                { attrs: { for: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("configuration.ip_filter")
                                    ) + "\n                          "
                                  ),
                                  _c("show-tip", {
                                    attrs: {
                                      type: "field",
                                      module: "configuration",
                                      tip: "tip_ip_filter",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: _vm.configForm.ip_filter
                                        ? "success"
                                        : "default",
                                    },
                                    model: {
                                      value: _vm.configForm.ip_filter,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "ip_filter",
                                          $$v
                                        )
                                      },
                                      expression: "configForm.ip_filter",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.activity_log")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: _vm.configForm.activity_log
                                        ? "success"
                                        : "default",
                                    },
                                    model: {
                                      value: _vm.configForm.activity_log,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "activity_log",
                                          $$v
                                        )
                                      },
                                      expression: "configForm.activity_log",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.trans("configuration.email_log"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: _vm.configForm.email_log
                                        ? "success"
                                        : "default",
                                    },
                                    model: {
                                      value: _vm.configForm.email_log,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "email_log",
                                          $$v
                                        )
                                      },
                                      expression: "configForm.email_log",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.email_template")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: _vm.configForm.email_template
                                        ? "success"
                                        : "default",
                                    },
                                    model: {
                                      value: _vm.configForm.email_template,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "email_template",
                                          $$v
                                        )
                                      },
                                      expression: "configForm.email_template",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("configuration.todo"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: _vm.configForm.todo
                                        ? "success"
                                        : "default",
                                    },
                                    model: {
                                      value: _vm.configForm.todo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.configForm, "todo", $$v)
                                      },
                                      expression: "configForm.todo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.trans("configuration.message"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: _vm.configForm.message
                                        ? "success"
                                        : "default",
                                    },
                                    model: {
                                      value: _vm.configForm.message,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.configForm, "message", $$v)
                                      },
                                      expression: "configForm.message",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.trans("configuration.backup"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: _vm.configForm.backup
                                        ? "success"
                                        : "default",
                                    },
                                    model: {
                                      value: _vm.configForm.backup,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.configForm, "backup", $$v)
                                      },
                                      expression: "configForm.backup",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                { attrs: { for: "" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans(
                                        "configuration.maintenance_mode"
                                      )
                                    ) + "\n                          "
                                  ),
                                  _c("show-tip", {
                                    attrs: {
                                      type: "field",
                                      module: "configuration",
                                      tip: "tip_maintenance_mode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: _vm.configForm.maintenance_mode
                                        ? "success"
                                        : "default",
                                    },
                                    model: {
                                      value: _vm.configForm.maintenance_mode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "maintenance_mode",
                                          $$v
                                        )
                                      },
                                      expression: "configForm.maintenance_mode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _vm.configForm.maintenance_mode
                              ? _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans(
                                            "configuration.maintenance_mode_message"
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("autosize-textarea", {
                                      staticClass: "form-control",
                                      attrs: {
                                        row: "1",
                                        placeholder: _vm.trans(
                                          "configuration.maintenance_mode_message"
                                        ),
                                        rows: "3",
                                        name: "maintenance_mode_message",
                                      },
                                      model: {
                                        value:
                                          _vm.configForm
                                            .maintenance_mode_message,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.configForm,
                                            "maintenance_mode_message",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configForm.maintenance_mode_message",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.configForm,
                                        "prop-name": "maintenance_mode_message",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-info waves-effect waves-light pull-right m-t-10",
                        attrs: { type: "submit" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.trans("general.save")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
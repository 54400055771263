<template>
  <div>
    <div class="page-titles p-3 border-bottom">
      <h3 class="text-themecolor">{{ trans('user.profile') }} <span class="card-subtitle">{{ getAuthUser('full_name') }} ({{
          getAuthUser('email')
        }})</span>
        <button class="btn btn-info btn-sm pull-right" @click="$router.push('/change-password')"><i
            class="fas fa-key"></i> <span class="d-none d-sm-inline">{{ trans('auth.change_password') }}</span></button>
      </h3>
    </div>
    <div class="row">
      <div class="col-12 col-sm-8">
        <div class="card border-right">
          <div class="card-body p-4">
            <h4 class="card-title">{{ trans('user.profile_info') }}</h4>
            <form @submit.prevent="updateProfile" @keydown="profileForm.errors.clear($event.target.name)">
              <div class="row">
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">{{ trans('user.first_name') }}</label>
                    <input class="form-control" type="text" name="first_name" v-model="profileForm.first_name"
                           :placeholder="trans('user.first_name')">
                    <show-error :form-name="profileForm" prop-name="first_name"></show-error>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">{{ trans('user.last_name') }}</label>
                    <input class="form-control" type="text" name="last_name" v-model="profileForm.last_name"
                           :placeholder="trans('user.last_name')">
                    <show-error :form-name="profileForm" prop-name="last_name"></show-error>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">{{ trans('user.email') }}</label>
                    <input class="form-control" type="email" name="email" v-model="profileForm.email"
                           :placeholder="trans('user.email')">
                    <show-error :form-name="profileForm" prop-name="email"></show-error>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">{{ trans('user.address_line') }}</label>
                    <input class="form-control" type="text" value="" v-model="profileForm.address_line_1"
                           name="address_line_1" :placeholder="trans('user.address_line')">
                    <show-error :form-name="profileForm" prop-name="address_line_1"></show-error>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">{{ trans('user.house_number') }}</label>
                    <input class="form-control" type="text" value="" v-model="profileForm.house_number_1"
                           name="house_number_1" :placeholder="trans('user.house_number')">
                    <show-error :form-name="profileForm" prop-name="house_number_1"></show-error>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">{{ trans('user.city') }}</label>
                    <input class="form-control" type="text" value="" v-model="profileForm.city" name="city"
                           :placeholder="trans('user.city')">
                    <show-error :form-name="profileForm" prop-name="city"></show-error>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">{{ trans('user.state') }}</label>
                    <input class="form-control" type="text" value="" v-model="profileForm.state" name="state"
                           :placeholder="trans('user.state')">
                    <show-error :form-name="profileForm" prop-name="state"></show-error>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">{{ trans('user.zipcode') }}</label>
                    <input class="form-control" type="text" value="" v-model="profileForm.zipcode" name="zipcode"
                           :placeholder="trans('user.zipcode')">
                    <show-error :form-name="profileForm" prop-name="zipcode"></show-error>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">{{ trans('user.country') }}</label>
                    <v-select
                        :options="countries"
                        :reduce="country_id => country_id.value"
                        v-model="profileForm.country_id"
                        :selected="profileForm.country_id"
                        label="text"
                        track-by="value"
                    ></v-select>
                    <show-error :form-name="profileForm" prop-name="country_id"></show-error>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">{{ trans('user.gender') }}</label>
                    <div class="radio radio-info">
                      <div class="row">
                        <div v-for="gender in genders" class="col-12 col-sm-4">
                          <div class="mr-3">
                            <input type="radio"
                                   :value="gender.id"
                                   :id="gender.id"
                                   v-model="profileForm.gender"
                                   name="gender"
                                   @click="profileForm.errors.clear('gender')">
                            <label :for="gender.id"> {{ trans('list.' + gender.id) }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <show-error :form-name="profileForm" prop-name="gender"></show-error>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">{{ trans('general.company') }}</label>
                    <input class="form-control" type="text" name="company" v-model="profileForm.company"
                           :placeholder="trans('general.company')">
                    <show-error :form-name="profileForm" prop-name="company"></show-error>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">{{ trans('general.company_position') }}</label>
                    <input class="form-control" type="text" name="company_position"
                           v-model="profileForm.company_position" :placeholder="trans('general.company_position')">
                    <show-error :form-name="profileForm" prop-name="company_position"></show-error>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">{{ trans('user.phone') }}</label>
                    <input class="form-control" type="text" value="" v-model="profileForm.phone"
                           name="phone" :placeholder="trans('user.phone')">
                    <show-error :form-name="profileForm" prop-name="phone"></show-error>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="">{{ trans('general.impressum_link') }}</label>
                    <input class="form-control" type="text" value="" v-model="profileForm.impressum_link"
                           name="impressum_link" :placeholder="trans('general.impressum')">
                    <show-error :form-name="profileForm" prop-name="impressum_link"></show-error>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="">{{ trans('general.data_protection_link') }}</label>
                    <input class="form-control" type="text" value="" v-model="profileForm.data_protection_link"
                           name="data_protection_link" :placeholder="trans('general.data_protection')">
                    <show-error :form-name="profileForm" prop-name="data_protection_link"></show-error>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-4 col-sm-12" v-if="getAuthUser('id')">
                  <div class="form-group">
                    <h4 class="card-title">{{ trans('general.company_logo') }}</h4>
                    <image-upload
                        ref="imageUpload"
                        :id="getAuthUser('id')"
                        :max-size="1000000"
                        :min-size="10"
                        :image-url="profileForm.image_url || imageUrl"
                        @fileSelected="onFileSelected"
                        @fileRemoved="onFileRemoved"
                    ></image-upload>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-info waves-effect waves-light pull-right m-t-10">
                {{ trans('general.save') }}
              </button>
            </form>
          </div>
        </div>
        <div class="row justify-content-center mt-3"
             v-show="profileForm.impressum_link & profileForm.data_protection_link">
          <div class="col-12-col-md-12">
            <a :href="profileForm.impressum_link" target="_blank"><label class="text-muted">{{
                trans('general.impressum')
              }}</label></a> |
            <a :href="profileForm.data_protection_link" target="_blank"><label
                class="text-muted">{{ trans('general.data_protection') }}</label></a>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-4">
        <div class="card">
          <!--          <div class="card-body p-4">
                      <h4 class="card-title">{{ trans('general.company_logo') }} <br>
                        <small class="text-muted">{{ trans('general.max_200_x_200_px') }}</small></h4>
                      <upload-image id="company-logo" :upload-path="`/user/profile/company-logo/${getAuthUser('id')}`"
                                    :remove-path="`/user/profile/company-logo/remove/${getAuthUser('id')}`"
                                    :image-source="company.logo" @uploaded="updateCompanyLogo"
                                    @removed="updateCompanyLogo"></upload-image>
                    </div>-->
        </div>
        <div class="card">
          <div class="card-body p-4">
            <LazyYoutube :src="videoUrl"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datepicker from 'vuejs-datepicker'
import imageUpload from '../../components/image-upload'
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"

export default {
  components: {datepicker, imageUpload, vSelect},
  data() {
    return {
      profileForm: new Form({
        first_name: '',
        last_name: '',
        email: '',
        address_line_1: '',
        house_number_1: '',
        city: '',
        state: '',
        zipcode: '',
        country_id: '',
        phone: '',
        company: '',
        company_position: '',
        company_logo: '',
        image: '',
        image_url: '',
        date_of_birth: '',
        date_of_anniversary: '',
        gender: 'male',
        impressum_link: '',
        data_protection_link: '',
        facebook_profile: '',
        twitter_profile: '',
        google_plus_profile: '',
        linkedin_profile: ''
      }, false),
      company: {
        logo: ''
      },
      genders: [],
      countries: [],
      user: {
        profile: {}
      },
      videoUrl: '',
      appUrl: process.env.MIX_PUBLIC_URL
    };
  },
  props: {
    imageUrl: {
      type: String,
      default: ''
    }
  },
  mounted() {
    axios.get('/api/user/pre-requisite')
        .then(response => response.data)
        .then(response => {
          this.countries = response.countries;
          this.videoUrl = response.videoUrl

        })
        .catch(error => {
          helper.showDataErrorMsg(error)
        });

    axios.get('/api/user/profile/pre-requisite')
        .then(response => response.data)
        .then(response => {
          this.user = response.user;
          this.profileForm.first_name = response.user.profile.first_name;
          this.profileForm.last_name = response.user.profile.last_name;
          this.profileForm.email = response.user.email;
          this.profileForm.phone = response.user.profile.phone;
          this.profileForm.address_line_1 = response.user.profile.address_line_1;
          this.profileForm.house_number_1 = response.user.profile.house_number_1;
          this.profileForm.city = response.user.profile.city;
          this.profileForm.state = response.user.profile.state;
          this.profileForm.zipcode = response.user.profile.zipcode;
          this.profileForm.country_id = response.user.profile.country_id;
          this.profileForm.company = response.user.profile.company;
          this.profileForm.company_position = response.user.profile.company_position;
          this.profileForm.company_logo = response.user.profile.company_logo;
          this.profileForm.date_of_birth = response.user.profile.date_of_birth;
          this.profileForm.date_of_anniversary = response.user.profile.date_of_anniversary;
          this.profileForm.gender = response.user.profile.gender;
          this.profileForm.facebook_profile = response.user.profile.facebook_profile;
          this.profileForm.twitter_profile = response.user.profile.twitter_profile;
          this.profileForm.google_plus_profile = response.user.profile.google_plus_profile;
          this.profileForm.linkedin_profile = response.user.profile.linkedin_profile;
          this.company.logo = response.user.profile.company_logo;
          this.genders = response.genders;
          this.profileForm.impressum_link = response.user.profile.impressum_link;
          this.profileForm.data_protection_link = response.user.profile.data_protection_link;
          if (response.media && response.media.original_url) {
            this.profileForm.image = response.media.original_url;
            this.profileForm.image_url = response.media.original_url;
          }
        })
        .catch(error => {
          helper.showDataErrorMsg(error);
        });
  },
  methods: {
    /*updateProfile() {
      this.profileForm.date_of_birth = (this.profileForm.date_of_birth) ? moment(this.profileForm.date_of_birth).format('YYYY-MM-DD') : null;
      this.profileForm.date_of_anniversary = (this.profileForm.date_of_anniversary) ? moment(this.profileForm.date_of_anniversary).format('YYYY-MM-DD') : null;
      this.profileForm.post('/api/user/profile/update')
          .then(response => {
            toastr.success(response.message);
            this.$store.dispatch('setAuthUserDetail', {
              first_name: this.profileForm.first_name,
              last_name: this.profileForm.last_name
            });
          }).catch(error => {
        helper.showErrorMsg(error);
      });
    },*/
    updateProfile() {
      // Create a new FormData object
      const formData = new FormData();

      // Convert dates to the desired format and add them to the FormData object
      if (this.profileForm.date_of_birth) {
        const dob = moment(this.profileForm.date_of_birth);
        if (dob.isValid()) {
          this.profileForm.date_of_birth = dob.format('YYYY-MM-DD');
          formData.append('date_of_birth', dob.toDate());
        }
      }
      if (this.profileForm.date_of_anniversary) {
        const doa = moment(this.profileForm.date_of_anniversary);
        if (doa.isValid()) {
          this.profileForm.date_of_anniversary = doa.format('YYYY-MM-DD');
          formData.append('date_of_anniversary', doa.toDate());
        }
      }

      // Add the rest of the profileForm data to the FormData object
      Object.keys(this.profileForm).forEach(key => {
        if (key !== 'date_of_birth' && key !== 'date_of_anniversary') {
          formData.append(key, this.profileForm[key]);
        }
      });

      // Send the FormData object in a POST request to the server
      axios.post('/api/user/profile/update', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        toastr.success(response.message);
        this.$store.dispatch('setAuthUserDetail', {
          first_name: this.profileForm.first_name,
          last_name: this.profileForm.last_name
        });
      }).catch(error => {
        helper.showErrorMsg(error);
      });
    },

    updateCompanyLogo(val) {
      this.$store.dispatch('setAuthUserDetail', {
        company_logo: val
      });
    },
    getAuthUser(name) {
      return helper.getAuthUser(name);
    },
    onFileSelected(file) {
      this.profileForm.image = file;
      this.profileForm.image_url = URL.createObjectURL(file);
    },
    onFileRemoved() {
      this.profileForm.image = null;
      this.profileForm.image_url = null;
    },
  },
  computed: {},
}
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function ($event) {
          return _vm.linkItemForm.errors.clear($event.target.name)
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-4 col-sm-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("link_item.name"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.linkItemForm.name,
                    expression: "linkItemForm.name",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  value: "",
                  name: "name",
                  placeholder: _vm.trans("general.name"),
                },
                domProps: { value: _vm.linkItemForm.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.linkItemForm, "name", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.linkItemForm, "prop-name": "name" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-info waves-effect waves-light pull-right",
          attrs: { type: "submit" },
        },
        [
          _vm.id
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))]),
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.id,
              expression: "id",
            },
          ],
          staticClass:
            "btn btn-danger waves-effect waves-light pull-right m-r-10",
          attrs: { to: "/link" },
        },
        [_vm._v("\n    " + _vm._s(_vm.trans("general.cancel")) + "\n  ")]
      ),
      _vm._v(" "),
      !_vm.id
        ? _c(
            "button",
            {
              staticClass:
                "btn btn-danger waves-effect waves-light pull-right m-r-10",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v(_vm._s(_vm.trans("general.cancel")) + "\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
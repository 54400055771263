var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "error-page", attrs: { id: "wrapper" } },
      [
        _c("div", { staticClass: "error-box" }, [
          _c("div", { staticClass: "error-body text-center" }, [
            _c("h2", [_vm._v("IP Restricted")]),
            _vm._v(" "),
            _c("p", { staticClass: "text-muted m-t-30 m-b-30" }, [
              _vm._v(
                "Unfortunately, This IP is not allowed to access this page!"
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "wrapper" } }, [
    _c(
      "div",
      {
        staticClass: "login-register",
        staticStyle: { "background-image": "url(/images/background.jpg)" },
      },
      [
        _c(
          "div",
          { staticClass: "login-box card" },
          [
            _c("div", { staticClass: "card-body p-4" }, [
              _c(
                "form",
                {
                  staticClass: "form-horizontal form-material",
                  attrs: { id: "loginform" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                    keydown: function ($event) {
                      return _vm.loginForm.errors.clear($event.target.name)
                    },
                  },
                },
                [
                  _c("h3", { staticClass: "box-title m-b-20" }, [
                    _vm._v(_vm._s(_vm.trans("auth.login"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group " },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.loginForm.email,
                            expression: "loginForm.email",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "email",
                          placeholder: _vm.trans("auth.email"),
                        },
                        domProps: { value: _vm.loginForm.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.loginForm,
                              "email",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.loginForm,
                          "prop-name": "email",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.loginForm.password,
                            expression: "loginForm.password",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "password",
                          name: "password",
                          placeholder: _vm.trans("auth.password"),
                        },
                        domProps: { value: _vm.loginForm.password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.loginForm,
                              "password",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.loginForm,
                          "prop-name": "password",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.getConfig("recaptcha") && _vm.getConfig("login_recaptcha")
                    ? _c("div", {
                        staticClass: "g-recaptcha",
                        attrs: {
                          "data-sitekey": _vm.getConfig("recaptcha_key"),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group text-center m-t-20" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light",
                        attrs: { type: "submit" },
                      },
                      [_vm._v(_vm._s(_vm.trans("auth.sign_in")))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group m-b-0" }, [
                    _c("div", { staticClass: "col-sm-12 text-center" }, [
                      _vm.getConfig("reset_password")
                        ? _c(
                            "p",
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("auth.forgot_your_password?")
                                ) + " "
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-info m-l-5",
                                  attrs: { to: "/password" },
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.trans("auth.reset_here!"))
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.getConfig("registration")
                        ? _c(
                            "p",
                            [
                              _vm._v(
                                _vm._s(_vm.trans("auth.create_account?")) + " "
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-info m-l-5",
                                  attrs: { to: "/register" },
                                },
                                [
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.trans("auth.sign_up"))),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.getConfig("social_login")
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col-12 col-sm-12 m-t-10 text-center",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "social" },
                              _vm._l(
                                _vm.social_login_providers,
                                function (provider) {
                                  return _vm.getConfig(provider + "_login")
                                    ? _c(
                                        "a",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.trans(
                                                "auth.login_with",
                                                { type: provider }
                                              ),
                                              expression:
                                                "trans('auth.login_with',{type:provider})",
                                            },
                                          ],
                                          class: [
                                            "btn",
                                            "btn-" + provider,
                                            "m-r-5",
                                            "no-hover",
                                          ],
                                          attrs: {
                                            href: "/auth/social/" + provider,
                                          },
                                        },
                                        [
                                          _c("i", {
                                            class: ["fab", "fa-" + provider],
                                          }),
                                        ]
                                      )
                                    : _vm._e()
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.getConfig("mode")
                    ? _c("div", { staticClass: "row m-t-10" }, [
                        _c("div", { staticClass: "col-6 text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn bt-block btn-info",
                              attrs: { type: "button" },
                              on: { click: _vm.loginAsAdmin },
                            },
                            [_vm._v("Login as Admin")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6 text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn bt-block btn-success",
                              attrs: { type: "button" },
                              on: { click: _vm.loginAsUser },
                            },
                            [_vm._v("Login as User")]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("guest-footer"),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
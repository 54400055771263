var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("permission.assign_permission")) + "\n      "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function ($event) {
                return _vm.$router.push("/home")
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home"))),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right m-r-10",
            on: {
              click: function ($event) {
                return _vm.$router.push("/configuration/permission")
              },
            },
          },
          [
            _c("i", { staticClass: "fas fa-key" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("permission.permission"))),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "table-responsive m-b-20" }, [
                _c("table", { staticClass: "table table-hover" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.search,
                              expression: "search",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", name: "search" },
                          domProps: { value: _vm.search },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.search = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "th",
                        { attrs: { colspan: "6" } },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-sm btn-info waves-effect waves-light m-l-20 pull-right",
                              attrs: { type: "button" },
                              on: { click: _vm.savePermission },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.trans("general.save")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-sm btn-danger pull-right",
                              attrs: { to: "/configuration/permission" },
                            },
                            [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(_vm.trans("general.back")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tr",
                      [
                        _c("th", [
                          _vm._v(_vm._s(_vm.trans("permission.permission"))),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.roles, function (role) {
                          return _c("th", { staticClass: "text-center" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.assignPermissionForm.data[role.id]
                                        .checkAll,
                                    expression:
                                      "assignPermissionForm.data[role.id].checkAll",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.assignPermissionForm.data[role.id]
                                      .checkAll
                                  )
                                    ? _vm._i(
                                        _vm.assignPermissionForm.data[role.id]
                                          .checkAll,
                                        null
                                      ) > -1
                                    : _vm.assignPermissionForm.data[role.id]
                                        .checkAll,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a =
                                          _vm.assignPermissionForm.data[role.id]
                                            .checkAll,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.assignPermissionForm.data[
                                                role.id
                                              ],
                                              "checkAll",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.assignPermissionForm.data[
                                                role.id
                                              ],
                                              "checkAll",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.assignPermissionForm.data[
                                            role.id
                                          ],
                                          "checkAll",
                                          $$c
                                        )
                                      }
                                    },
                                    function ($event) {
                                      return _vm.topCheckChanged(role.id)
                                    },
                                  ],
                                },
                              }),
                              _vm._v(
                                "\n                      " +
                                  _vm._s(role.name) +
                                  "\n                    "
                              ),
                            ]),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.permissions, function (permission) {
                        return _c(
                          "tr",
                          [
                            _c("td", [
                              _vm._v(_vm._s(_vm.toWord(permission.name))),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.roles, function (role) {
                              return _c(
                                "td",
                                { staticClass: "text-center" },
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: _vm.assignPermissionForm.data[
                                        role.id
                                      ][permission.id]
                                        ? "success"
                                        : "default",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.permissionCheckChanged(
                                          role.id,
                                          permission.id
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.assignPermissionForm.data[role.id][
                                          permission.id
                                        ],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.assignPermissionForm.data[
                                            role.id
                                          ],
                                          permission.id,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "assignPermissionForm.data[role.id][permission.id]",
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _c("tr", [
                        _c(
                          "th",
                          { attrs: { colspan: "12" } },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-sm btn-info waves-effect waves-light m-l-20 pull-right",
                                attrs: { type: "button" },
                                on: { click: _vm.savePermission },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.trans("general.save")) +
                                    "\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "btn btn-sm btn-danger pull-right",
                                attrs: { to: "/configuration/permission" },
                              },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.trans("general.back")) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-lg-12 col-md-12" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }